import { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { image_user } from '../../utils/images';

const ProfileHeader = ({ userInfo }) => {
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    name: '',
    role: '',
    designation: '',
    image: '',
  });

  // Populate profileData once userInfo is available
  useEffect(() => {
    if (userInfo) {
      setProfileData({
        name: userInfo?.name || 'Unknown User',
        role: userInfo?.role || 'Unknown Role',
        designation: userInfo?.designation || 'Unknown Designation',
        image: userInfo?.image || 'Unknown Image',
      });
      setLoading(false);
    }
  }, [userInfo]);
  
  const imageSrc = profileData?.image === undefined ? profileData?.image : image_user;

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <div className="card user-info-card mb-3">
        <div className="card-body d-flex align-items-center">
          <div className="user-profile me-3">
            <img src={imageSrc} alt="Profile" />
            <i className="bi bi-pencil"></i>
            <form action="#">
              <input className="form-control" type="file" />
            </form>
          </div>
          <div className="user-info">
            <div className="d-flex align-items-center">
              <h5 className="mb-1">{profileData.name}</h5>
              <span className="badge bg-warning ms-2 rounded-pill">{profileData.role}</span>
            </div>
            <p className="mb-0">{profileData.designation}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHeader;
