import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { showAlertMessage } from '../../utils/sweetAlert';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { patchIpd } from '../../store/slices/ipdSlice';
import { fetchBeds } from '../../store/slices/bedSlice';
import Select from 'react-select';

const Switch = ({ showSwitchModal, setShowSwitchModal, data }) => {
    const staff_id = localStorage.getItem('_Auth_id');
    const dispatch = useDispatch();

    // Fetching bed details and mapping them for the Select component
    useEffect(() => {
        showSwitchModal && dispatch(fetchBeds())
    }, [dispatch, showSwitchModal]);

    const bedInfo = useSelector((state) => state.bed.beds);
    const [bedOptions, setBedOptions] = useState([]);

    useEffect(() => {
        if (bedInfo) {
            setBedOptions(
                bedInfo
                    .filter((item) => item._id !== data?.bed?._id)
                    .map((item) => ({ value: item._id, label: item.name }))
            );

        }
    }, [bedInfo, data]);

    // Formik setup
    const formik = useFormik({
        initialValues: {
            name: `${data?.patient?.name} - ${data?.patient?.uhid} (${data?.bed?.name})` || '',
            staff: staff_id,
            id: data?._id,
            old_bed: data?.bed?._id,
            bed: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Patient is required'),
            bed: Yup.string().required('Bed is required'),
        }),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);
            try {
                const query = {
                    _id: values.id,
                    patchedIpd: values,
                }
                const response = await dispatch(patchIpd(query));
                if (response?.payload?.success) {
                    await showAlertMessage({ text: response?.payload?.message });
                    actions.resetForm();
                    setShowSwitchModal(false);
                } else {
                    throw new Error(response?.payload?.message);
                }
            } catch (error) {
                showAlertMessage({
                    icon: 'error',
                    title: 'Error occurred!',
                    text: error.message,
                });
            } finally {
                actions.setSubmitting(false);
            }
        },
    });

    return (
        <>
            <Modal show={showSwitchModal} onHide={() => setShowSwitchModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="element-heading">
                            <h6>Switch Bed {data?.patient?.name} - {data?.patient?.uhid} ({data?.srNo}) </h6>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="row mb-3">
                            <div className="form-group mb-3">
                                <input
                                    name="name"
                                    id='name'
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    readOnly // Making this field read-only as it's pre-filled
                                />
                            </div>
                            {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger mb-1">{formik.errors.name}</div>
                            ) : null}
                        </div>
                        <div className="row mb-3">
                            <div className="form-group mb-3">
                                <Select
                                    name="bed"
                                    placeholder="Bed"
                                    options={bedOptions}
                                    value={bedOptions.find(option => option.value === formik.values.bed)}
                                    onChange={(selectedOption) => formik.setFieldValue('bed', selectedOption ? selectedOption.value : '')}
                                />
                                {formik.touched.bed && formik.errors.bed ? (
                                    <div className="text-danger mb-1">{formik.errors.bed}</div>
                                ) : null}
                            </div>
                            {formik.touched.dod && formik.errors.dod ? (
                                <div className="text-danger mb-1">{formik.errors.dod}</div>
                            ) : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className="btn m-1 btn-sm btn-danger" onClick={() => setShowSwitchModal(false)}>
                            Close
                        </button>
                        <button type="submit" className="btn m-1 btn-sm btn-success" disabled={formik.isSubmitting}>
                            {formik.isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default Switch;
