import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import '../../assets/css/custom/OPDList.css';
import { format } from 'date-fns';
import { fetchOpds } from "../../store/slices/opdSlice";
import { PatientFilter } from "../../components/common/PatientFilter";
import { Spinner } from "react-bootstrap";

const OPDList = () => {
  const navigate = useNavigate();
  const { opds, loading } = useSelector((state) => state.opd);
  const sortedOpds = [...opds].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const [searchTerm, setSearchTerm] = useState(''); // Independent search state

  // Search functionality (Filter functionality)
  const filteredOpds = sortedOpds.filter((opd) => {
    return (
      opd?.patient?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      opd?.patient?.mobile.includes(searchTerm) ||
      opd?.patient?.uhid.includes(searchTerm)
    );
  });

  // Handle Update
  const handleUpdate = (id) => {
    navigate(`/opd/edit/${id}`);
  };

  const handleReceiptPrint = (data) => {
    navigate('/opd/print/receipt', { state: { data } })
  };

  //Handle print header
  const handlePrescriptionHeaderPrint = (data) => {
    navigate('/opd/print/prescription', { state: { data } })
  };

  // Calculation function
  const totals = opds.reduce(
    (acc, { total_amount, transaction_type }) => ({
      ...acc,
      [transaction_type]: acc[transaction_type] + total_amount,
      total: acc.total + total_amount,
    }),
    { cash: 0, online: 0, total: 0 }
  );

  return (
    <>
      <div className="page-content-wrapper py-3">
        <PatientFilter fetchQuery={fetchOpds} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {/* Loading State */}
        {loading ? (
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" size="lg" variant="primary" />
                </div>
              </div>
            </div>
          </div>
        ) : filteredOpds && filteredOpds?.length > 0 ? (
          // Display OPD Records
          <>
            <div className="container">
              <div className="element-heading mt-3 inline">
                <h6>OPDs</h6>
                <span className="text-warning fw-bold">{`C: ${totals.cash}/- + O: ${totals.online}/- = T: ${totals.total}/-`}</span>
                <i className="fa-solid fa-chart-simple text-info fw-bold"> {filteredOpds.length}</i>
              </div>
            </div>
            {filteredOpds.map((item, index) => (
              <div key={index} className="container mb-1">
                <div className="alert unread custom-alert-1 alert-dark bg-white">
                  <div className="alert-text w-100">
                    <div className="card-ipd-head">
                      <div className="text-black fw-bold">{item?.srNo}</div>
                      <div className="text-black">UHID: {item?.patient?.uhid}</div>
                      <div className="text-danger fw-bold">
                        {`${item?.payment_status}/${item?.transaction_type}`}
                      </div>
                      <span className="text-truncate text-info fw-bold">
                        {`${item?.patient.age}/${({ male: 'M', female: 'F' }[item?.patient.sex] || 'O')}`}
                      </span>
                    </div>
                    <div className="ipd-body">
                      <div className="ipd-body-left">
                        <span className="text-info fw-bold text-truncate">
                          {item?.patient.name}
                        </span>
                        <span className="text-truncate">A: {item?.patient.city}</span>
                        <span className="text-truncate">T: {format(new Date(item?.createdAt), 'dd-MM-yyyy hh:mm:ss a')}</span>
                      </div>
                      <div className="ipd-body-right">
                        <span className="text-truncate fw-bolder text-dark">
                          Rs: {item?.total_amount}/-
                        </span>
                        <span>{item?.patient.mobile}</span>
                      </div>
                    </div>
                    <div className="ipd-buttons">
                      <button
                        onClick={() => handleUpdate(item?._id)}
                        className="btn btn-info btn-sm"
                      >
                        Update
                      </button>
                      <button
                        onClick={() => handlePrescriptionHeaderPrint(item)}
                        className="btn btn-primary btn-sm">
                        Prescription
                      </button>
                      <button
                        onClick={() => handleReceiptPrint(item)}
                        className="btn btn-success btn-sm">
                        Receipt
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="container">
            <div className="card">
              <div className="card-body">
                <p className="text-center">No OPD records found.</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="add-new-contact-wrap">
        <Link to="/opd/add"><i className="bi bi-plus-lg"></i></Link>
      </div>
    </>
  );
};

export default OPDList;
