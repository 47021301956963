import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../utils/configs';
import cookie from 'react-cookies';

// Base URL for API
const API_URL = `${baseUrl}opd`;

// Function to retrieve auth token (async token handling for flexibility)
const getAuthToken = () => cookie.load('_auth');

// Async thunk to fetch all OPD records
export const fetchOpds = createAsyncThunk('opd/fetchOpds', async (query, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${API_URL}/all`, {
      headers: {
        Authorization: token,
      },
      params: query,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Async thunk to add a new OPD record
export const addOpd = createAsyncThunk('opd/addOpd', async (newOpd, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    const response = await axios.post(`${API_URL}`, newOpd, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Async thunk to update an existing OPD record
export const updateOpd = createAsyncThunk('opd/updateOpd', async ({ id, updatedOpd }, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    const response = await axios.put(`${API_URL}/${id}`, updatedOpd, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Async thunk to delete an OPD record
export const deleteOpd = createAsyncThunk('opd/deleteOpd', async (id, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    await axios.delete(`${API_URL}/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    return id;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

const opdSlice = createSlice({
  name: 'opd',
  initialState: {
    opds: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    clearOpds: (state) => {
      state.opds = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch OPDs
      .addCase(fetchOpds.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOpds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.opds = action.payload.data;
        state.error = null;
      })
      .addCase(fetchOpds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Add OPD
      .addCase(addOpd.fulfilled, (state, action) => {
        state.opds.push(action.payload);
        state.error = null;
      })
      .addCase(addOpd.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })

      // Update OPD
      .addCase(updateOpd.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.opds.findIndex((opd) => opd._id === action.payload.data._id);
        if (index !== -1) {
          state.opds[index] = action.payload.data;
        }
        state.error = null;
      })
      .addCase(updateOpd.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Delete OPD
      .addCase(deleteOpd.fulfilled, (state, action) => {
        state.opds = state.opds.filter((opd) => opd._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteOpd.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export const { clearOpds } = opdSlice.actions;
export const opdReducer = opdSlice.reducer;
