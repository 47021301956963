import Select from 'react-select';
import { serviceOptions } from '../../utils/constants';
const ServiceTable = ({ formik }) => {
    // Service handling functions 
    const handleAmountChange = (service, amount) => {
        formik.setFieldValue([
            { value: '', label: 'Select a service' },
            ...service.map((item) => ({
                value: item._id,
                label: `${item.name} - ₹${item.amount}`, // Display service name with amount
            })),
        ]);
    };

    const handleAddService = () => {
        const { newService, newServiceAmount } = formik.values;
        if (newService && newServiceAmount) {
            formik.setFieldValue('services', {
                ...formik.values.services,
                [newService]: newServiceAmount,
            });
            formik.setFieldValue('newService', '');
            formik.setFieldValue('newServiceAmount', '');
        }
    };

    const handleRemoveService = (service) => {
        const updatedServices = { ...formik.values.services };
        delete updatedServices[service];
        formik.setFieldValue('services', updatedServices);
    };
    return (
        <>
            <div className="row g-lg-2 mb-0">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th style={{ width: "45%" }}>Service</th>
                            <th style={{ width: "45%" }}>Amount</th>
                            <th style={{ width: "10%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Fixed Row for Adding New Service */}
                        <tr>
                            <td>
                                <Select
                                    name={`newService`}
                                    options={serviceOptions}
                                    placeholder="Select Service"
                                    onChange={(selectedOption) => {
                                        formik.setFieldValue('newService', selectedOption.value);
                                        const selectedAmount = serviceOptions.find(option => option.value === selectedOption.value)?.amount; // Adjust this if your serviceOptions have a different structure
                                        formik.setFieldValue('newServiceAmount', selectedAmount || '');
                                    }}
                                    isClearable={false} // Prevent clearing
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    name="newServiceAmount"
                                    className="form-control"
                                    placeholder="Amount"
                                    onChange={(e) => formik.setFieldValue('newServiceAmount', e.target.value)}
                                    value={formik.values.newServiceAmount}
                                />
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="btn m-1 btn-sm btn-success"
                                    onClick={handleAddService} // Function to add new service
                                >
                                    <i className="fa-solid fa-plus"></i>
                                </button>
                            </td>
                        </tr>

                        {/* Existing Services Rows */}
                        {Object.entries(formik.values.services).map(([service, amount], index) => (
                            <tr key={index + 1}> {/* Add 1 to the index for unique key */}
                                <td>
                                    <Select
                                        name={`services.${service}`}
                                        options={serviceOptions}
                                        value={serviceOptions.find(option => option.value === service) || null}
                                        onChange={(selectedOption) => {
                                            const selectedAmount = serviceOptions.find(option => option.value === selectedOption.value)?.amount; // Adjust this if your serviceOptions have a different structure
                                            formik.setFieldValue(`services.${service}`, selectedOption.value);
                                            formik.setFieldValue(`servicesAmount.${service}`, selectedAmount || '');
                                        }}
                                        isClearable={false} // Prevent clearing
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        name={`servicesAmount.${service}`} // This is updated to reflect the service's amount
                                        className="form-control"
                                        placeholder="Amount"
                                        onChange={(e) => handleAmountChange(service, e.target.value)}
                                        value={amount}
                                    />
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn m-1 btn-sm btn-danger"
                                        onClick={() => handleRemoveService(service)}
                                    >
                                        <i className="fa-solid fa-minus"></i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ServiceTable
