
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { showAlertMessage } from '../../utils/sweetAlert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addBed, fetchBeds } from '../../store/slices/bedSlice';

const AddBed = ({ showBedModal, setShowBedModal }) => {
  const staff_id = localStorage.getItem('_Auth_id');
  const dispatch = useDispatch();

  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: '',
      staff: staff_id,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Bed name is required'),
    }),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      try {
        const response = await dispatch(addBed(values));
        if (response?.payload?.success) {
          await showAlertMessage({ text: response?.payload?.message });
          // Dispatch fetchBeds after form reset
          dispatch(fetchBeds({ isOccupied: false }));
          actions.resetForm();
          setShowBedModal(false);
        } else {
          throw new Error(response?.payload?.message);
        }
      } catch (error) {
        showAlertMessage({
          icon: 'error',
          title: 'Error occurred!',
          text: error.message,
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
  });
  return (
    <>
      <Modal show={showBedModal} onHide={showBedModal}>
        <Modal.Header>
          <Modal.Title><div className="element-heading">
            <h6>Add Bed</h6>
          </div></Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="row mb-3">
              <div className="form-group mb-3">
                <input
                  name="name"
                  id='name'
                  className="form-control"
                  type="text"
                  placeholder="Bed Name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  autoFocus
                />
              </div>
              {formik.touched.name && formik.errors.name ? (
                <div className="text-danger mb-1">{formik.errors.name}</div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' className="btn m-1 btn-sm btn-danger" onClick={() => setShowBedModal(false)}>
              Close
            </button>
            <button type="submit" className="btn m-1 btn-sm btn-success" disabled={formik.isSubmitting}>
              {formik.isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
};

export default AddBed
