import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; // Import js-cookie for handling cookies
import { baseUrl } from '../../utils/configs';
import cookie from 'react-cookies'

// Base URL for API
const API_URL = `${baseUrl}ipd`;

// Get the auth token from the cookie
const AuthToken = await cookie.load('_auth')

// Async thunk to fetch all IPD records
export const fetchIpds = createAsyncThunk('ipd/fetchIpds', async (query) => {
  const response = await axios.get(`${API_URL}`, {
    headers: {
      Authorization: AuthToken,
    },
    params: query,
  });
  return response.data;
});

// Async thunk to add a new IPD record
export const addIpd = createAsyncThunk('ipd/addIpd', async (newIpd) => {
  const response = await axios.post(`${API_URL}`, newIpd, {
    headers: {
      Authorization: AuthToken,
    },
  });
  return response.data;
});

// Async thunk to update an existing IPD record
export const updateIpd = createAsyncThunk('ipd/updateIpd', async ({ id, updatedIpd }) => {
  const response = await axios.put(`${API_URL}/${id}`, updatedIpd, {
    headers: {
      Authorization: AuthToken,
    },
  });
  return response.data;
});

// Async thunk to Patch an existing IPD record
export const patchIpd = createAsyncThunk('ipd/patchIpd', async ({ _id, patchedIpd }) => {
  const response = await axios.patch(`${API_URL}/${_id}`, patchedIpd, {
    headers: {
      Authorization: AuthToken,
    },
  });
  return response.data;
});

// Async thunk to delete an IPD record
export const deleteIpd = createAsyncThunk('ipd/deleteIpd', async (id) => {
  await axios.delete(`${API_URL}/${id}`, {
    headers: {
      Authorization: AuthToken,
    },
  });
  return id;
});

const ipdSlice = createSlice({
  name: 'ipd',
  initialState: {
    ipds: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    clearIpds: (state) => {
      state.ipds = [];
    },
    // other reducers
  },
  extraReducers: (builder) => {
    // Fetch IPDs
    builder
      .addCase(fetchIpds.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIpds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.ipds = action.payload.data;
      })
      .addCase(fetchIpds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // Add IPD
    builder
      .addCase(addIpd.fulfilled, (state, action) => {
        state.ipds.push(action.payload);
      });

    // Update IPD
    builder
      .addCase(updateIpd.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateIpd.fulfilled, (state, action) => {
        const index = state.ipds.findIndex((ipd) => ipd._id === action.payload._id);
        if (index !== -1) {
          state.ipds[index] = action.payload;
        }
      })
      .addCase(updateIpd.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
    // Patch IPD
    builder
      .addCase(patchIpd.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(patchIpd.fulfilled, (state, action) => {
        const index = state.ipds.findIndex((ipd) => ipd._id === action.payload._id);
        if (index !== -1) {
          state.ipds[index] = action.payload;
        }
      })
      .addCase(patchIpd.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
    // Delete IPD
    builder
      .addCase(deleteIpd.fulfilled, (state, action) => {
        state.ipds = state.ipds.filter((ipd) => ipd._id !== action.payload);
      });
  },
});

export const { clearIpds } = ipdSlice.actions;
export const ipdReducer = ipdSlice.reducer;
