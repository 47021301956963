
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { showAlertMessage } from '../../utils/sweetAlert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addScalp, fetchScalps } from '../../store/slices/scalpSlice';
import Select from 'react-select';
import { fetchPatients } from '../../store/slices/patientSlice';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { format } from 'date-fns';

const AddScalp = ({ showAddScalpsModal, setShowAddScalpsModal }) => {
    const staff_id = localStorage.getItem('_Auth_id');
    const dispatch = useDispatch();
    const { options, isLoading } = useSelector((state) => state.patient);

    // Select doctor
    const doctorInfo = useSelector((state) => state.doctor.doctorDetails);
    const doctorOptions = doctorInfo?.map((item) => ({
        value: item._id,
        label: item.name,
    })) || [];

    // Search for patients
    const handleSearch = (query) => {
        dispatch(fetchPatients(query));
    };

    // Formik setup
    const formik = useFormik({
        initialValues: {
            name: '',
            patient: '',
            doa: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
            staff: staff_id,
            doctor: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            doctor: Yup.string().required('Doctor is required'),
            patient: Yup.string().required('Patient is required'),
            doa: Yup.string().required('Date time of admission is required'),
        }),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);
            try {
                const response = await dispatch(addScalp(values));
                if (response?.payload?.success) {
                    await showAlertMessage({ text: response?.payload?.message });
                    actions.resetForm();
                    setShowAddScalpsModal(false);
                } else {
                    throw new Error(response?.payload?.message);
                }
            } catch (error) {
                showAlertMessage({
                    icon: 'error',
                    title: 'Error occurred!',
                    text: error.message,
                });
            } finally {
                actions.setSubmitting(false);
            }
        },
    });
    return (
        <>
            <Modal show={showAddScalpsModal} onHide={showAddScalpsModal}>
                <Modal.Header>
                    <Modal.Title><div className="element-heading">
                        <h6>Add Scalp</h6>
                    </div></Modal.Title>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="form-group mb-3">
                            <label className="form-label" htmlFor="doctor">Doctor</label>
                            <Select
                                name="doctor"
                                placeholder="Doctor"
                                options={doctorOptions}
                                value={doctorOptions.find(option => option.value === formik.values.doctor)}
                                onChange={(selectedOption) => {
                                    formik.setFieldValue('doctor', selectedOption ? selectedOption.value : '');
                                    formik.setFieldTouched('doctor', true);
                                }}
                                autoFocus
                            />
                            {formik.touched.doctor && formik.errors.doctor && (
                                <div className="text-danger mb-1">{formik.errors.doctor}</div>
                            )}
                        </div>
                        <div className="form-group mb-3">
                            <AsyncTypeahead
                                filterBy={() => true}
                                id="async-patient-search"
                                isLoading={isLoading}
                                labelKey="name"
                                minLength={3}
                                onSearch={handleSearch}
                                options={options}
                                placeholder="Search for a patient..."
                                selected={formik.values.name ? [{ name: formik.values.name }] : []}
                                renderMenuItemChildren={(option) => (
                                    <>
                                        <span>{option.name}</span>
                                        {option.uhid && (
                                            <>
                                                <small> - {option.uhid}</small>
                                                <p className="mb-0">{`${option.mobile} | ${option.age}/${option.sex === 'male' ? 'M' : option.sex === 'female' ? 'F' : 'O'} | ${option.city}`}</p>
                                                <hr className="mt-1 mb-0" />
                                            </>
                                        )}
                                    </>
                                )}
                                onChange={(selected) => {
                                    if (selected.length > 0) {
                                        formik.setFieldValue('name', selected[0].name);
                                        formik.setFieldValue('patient', selected[0]._id);
                                    }
                                }}
                            />
                            {formik.touched.patient && formik.errors.patient ? (
                                <div className="text-danger mb-1">{formik.errors.patient}</div>
                            ) : null}
                        </div>
                        <div className="form-group mb-3">
                            <label className="form-label" htmlFor="doa">Date Time of Admission</label>
                            <input
                                name="doa"
                                id="doa"
                                className="form-control"
                                type="datetime-local"
                                value={formik.values.doa}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className="btn m-1 btn-sm btn-danger" onClick={() => setShowAddScalpsModal(false)}>
                            Close
                        </button>
                        <button type="submit" className="btn m-1 btn-sm btn-success" disabled={formik.isSubmitting}>
                            {formik.isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
};

export default AddScalp
