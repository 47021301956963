import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; // Import js-cookie for handling cookies
import { baseUrl } from '../../utils/configs';
import cookie from 'react-cookies'

// Base URL for API
const API_URL = `${baseUrl}scalp`;

// Get the auth token from the cookie
const AuthToken = await cookie.load('_auth')

// Async thunk to fetch all Scalp records
export const fetchScalps = createAsyncThunk('scalp/fetchScalps', async (query) => {
    const response = await axios.get(`${API_URL}`, {
        headers: {
            Authorization: AuthToken,
        },
        params: query,
    });
    return response.data;
});

// Async thunk to add a new Scalp record
export const addScalp = createAsyncThunk('scalp/addScalp', async (newScalp) => {
    const response = await axios.post(`${API_URL}`, newScalp, {
        headers: {
            Authorization: AuthToken,
        },
    });
    return response.data;
});

// Async thunk to update an existing Scalp record
export const updateScalp = createAsyncThunk('scalp/updateScalp', async ({ id, updatedScalp }) => {
    const response = await axios.put(`${API_URL}/${id}`, updatedScalp, {
        headers: {
            Authorization: AuthToken,
        },
    });
    return response.data;
});

// Async thunk to Patch an existing Scalp record
export const patchScalp = createAsyncThunk('scalp/patchScalp', async ({ _id, patchedScalp }) => {
    const response = await axios.patch(`${API_URL}/${_id}`, patchedScalp, {
        headers: {
            Authorization: AuthToken,
        },
    });
    return response.data;
});

// Async thunk to delete an Scalp record
export const deleteScalp = createAsyncThunk('scalp/deleteScalp', async (id) => {
    await axios.delete(`${API_URL}/${id}`, {
        headers: {
            Authorization: AuthToken,
        },
    });
    return id;
});

const scalpSlice = createSlice({
    name: 'scalp',
    initialState: {
        scalps: [],
        status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
        error: null,
    },
    reducers: {
        clearScalps: (state) => {
            state.scalps = [];
        },
        // other reducers
    },
    extraReducers: (builder) => {
        // Fetch Scalps
        builder
            .addCase(fetchScalps.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchScalps.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.scalps = action.payload.data;
            })
            .addCase(fetchScalps.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });

        // Add Scalp
        builder
            .addCase(addScalp.fulfilled, (state, action) => {
                state.scalps.push(action.payload);
            });

        // Update Scalp
        builder
            .addCase(updateScalp.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateScalp.fulfilled, (state, action) => {
                const index = state.scalps.findIndex((scalp) => scalp._id === action.payload._id);
                if (index !== -1) {
                    state.scalps[index] = action.payload;
                }
            })
            .addCase(updateScalp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        // Patch Scalp
        builder
            .addCase(patchScalp.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(patchScalp.fulfilled, (state, action) => {
                const index = state.scalps.findIndex((scalp) => scalp._id === action.payload._id);
                if (index !== -1) {
                    state.scalps[index] = action.payload;
                }
            })
            .addCase(patchScalp.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        // Delete Scalp
        builder
            .addCase(deleteScalp.fulfilled, (state, action) => {
                state.scalps = state.scalps.filter((scalp) => scalp._id !== action.payload);
            });
    },
});

export const { clearScalps } = scalpSlice.actions;
export const scalpReducer = scalpSlice.reducer;
