import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();

  signOut();
  navigate('/auth/login');
  localStorage.removeItem("_Auth_id");
  window.location.reload();

};

export default Logout
