
const Doctors = () => {
  return (
    <>
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="element-heading">
            <h6 className="ps-1">Recent contacts</h6>
          </div>
          <ul className="ps-0 chat-user-list">
            <li className="p-3 chat-unread">
              <a className="d-flex" href="chat.html">
                <div className="chat-user-thumbnail me-3 shadow">
                  <img className="img-circle" src="img/bg-img/user1.png" alt="" />
                  <span className="active-status"></span>
                </div>
                <div className="chat-user-info">
                  <h6 className="text-truncate mb-0">Designing World</h6>
                  <div className="last-chat">
                    <p className="mb-0 text-truncate">Hello, Are you there?
                      <span className="badge rounded-pill bg-primary">2</span>
                    </p>
                  </div>
                </div>
              </a>
              <div className="dropstart chat-options-btn">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="bi bi-mic-mute"></i>Mute</a></li>
                  <li><a href="#"><i className="bi bi-slash-circle"></i>Ban</a></li>
                  <li><a href="#"><i className="bi bi-trash"></i>Remove</a></li>
                </ul>
              </div>
            </li>
            <li className="p-3">
              <a className="d-flex" href="chat.html">
                <div className="chat-user-thumbnail me-3 shadow">
                  <img className="img-circle" src="img/bg-img/user2.png" alt="" />
                  <span className="active-status"></span>
                </div>
                <div className="chat-user-info">
                  <h6 className="text-truncate mb-0">Mustafa Rabbi</h6>
                  <div className="last-chat">
                    <p className="text-truncate mb-0">How can I help you?</p>
                  </div>
                </div>
              </a>
              <div className="dropstart chat-options-btn">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="bi bi-mic-mute"></i>Mute</a></li>
                  <li><a href="#"><i className="bi bi-slash-circle"></i>Ban</a></li>
                  <li><a href="#"><i className="bi bi-trash"></i>Remove</a></li>
                </ul>
              </div>
            </li>
            <li className="p-3 offline">
              <a className="d-flex" href="chat.html">
                <div className="chat-user-thumbnail me-3 shadow">
                  <img className="img-circle" src="img/bg-img/user3.png" alt="" />
                  <span className="active-status"></span>
                </div>
                <div className="chat-user-info">
                  <h6 className="text-truncate mb-0">Affan Islam</h6>
                  <div className="last-chat">
                    <p className="text-truncate mb-0">I want to buy your Affan template.</p>
                  </div>
                </div>
              </a>
              <div className="dropstart chat-options-btn">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="bi bi-mic-mute"></i>Mute</a></li>
                  <li><a href="#"><i className="bi bi-slash-circle"></i>Ban</a></li>
                  <li><a href="#"><i className="bi bi-trash"></i>Remove</a></li>
                </ul>
              </div>
            </li>
            <li className="p-3">
              <a className="d-flex" href="chat.html">
                <div className="chat-user-thumbnail me-3 shadow">
                  <img className="img-circle" src="img/bg-img/user4.png" alt="" />
                  <span className="active-status"></span>
                </div>
                <div className="chat-user-info">
                  <h6 className="text-truncate mb-0">Jannatun Lima</h6>
                  <div className="last-chat">
                    <p className="text-truncate mb-0">Affan - PWA Mobile HTML Template</p>
                  </div>
                </div>
              </a>
              <div className="dropstart chat-options-btn">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="bi bi-mic-mute"></i>Mute</a></li>
                  <li><a href="#"><i className="bi bi-slash-circle"></i>Ban</a></li>
                  <li><a href="#"><i className="bi bi-trash"></i>Remove</a></li>
                </ul>
              </div>
            </li>
            <li className="p-3">
              <a className="d-flex" href="chat.html">
                <div className="chat-user-thumbnail me-3 shadow">
                  <img className="img-circle" src="#" alt="" />
                  <span className="active-status"></span>
                </div>
                <div className="chat-user-info">
                  <h6 className="text-truncate mb-0">Waves Motion</h6>
                  <div className="last-chat">
                    <p className="text-truncate mb-0"><i className="bi bi-file-earmark-zip"></i>affan-pwa-mobile.zip</p>
                  </div>
                </div>
              </a>
              <div className="dropstart chat-options-btn">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="bi bi-mic-mute"></i>Mute</a></li>
                  <li><a href="#"><i className="bi bi-slash-circle"></i>Ban</a></li>
                  <li><a href="#"><i className="bi bi-trash"></i>Remove</a></li>
                </ul>
              </div>
            </li>
            <li className="p-3 offline">
              <a className="d-flex" href="chat.html">
                <div className="chat-user-thumbnail me-3 shadow">
                  <img className="img-circle" src="img/bg-img/user1.png" alt="" />
                  <span className="active-status"></span>
                </div>
                <div className="chat-user-info">
                  <h6 className="text-truncate mb-0">Samantha Sarah</h6>
                  <div className="last-chat">
                    <p className="text-truncate mb-0">Would you please provide a purchase link?</p>
                  </div>
                </div>
              </a>
              <div className="dropstart chat-options-btn">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="bi bi-mic-mute"></i>Mute</a></li>
                  <li><a href="#"><i className="bi bi-slash-circle"></i>Ban</a></li>
                  <li><a href="#"><i className="bi bi-trash"></i>Remove</a></li>
                </ul>
              </div>
            </li>
            <li className="p-3">
              <a className="d-flex" href="chat.html">
                <div className="chat-user-thumbnail me-3 shadow">
                  <img className="img-circle" src="img/bg-img/user2.png" alt="" />
                  <span className="active-status"></span>
                </div>
                <div className="chat-user-info">
                  <h6 className="text-truncate mb-0">Hasnain Ahmed</h6>
                  <div className="last-chat">
                    <p className="text-truncate mb-0">Sure, Here are the purchase link. Please click the purchase now button,
                      then fill up your all payment info.</p>
                  </div>
                </div>
              </a>
              <div className="dropstart chat-options-btn">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="bi bi-mic-mute"></i>Mute</a></li>
                  <li><a href="#"><i className="bi bi-slash-circle"></i>Ban</a></li>
                  <li><a href="#"><i className="bi bi-trash"></i>Remove</a></li>
                </ul>
              </div>
            </li>
            <li className="p-3 offline">
              <a className="d-flex" href="chat.html">
                <div className="chat-user-thumbnail me-3 shadow">
                  <img className="img-circle" src="img/bg-img/user3.png" alt="" />
                  <span className="active-status"></span>
                </div>
                <div className="chat-user-info">
                  <h6 className="text-truncate mb-0">Shaila Afrin</h6>
                  <div className="last-chat">
                    <p className="text-truncate mb-0">You are welcome &#128525; &#128525;</p>
                  </div>
                </div>
              </a>
              <div className="dropstart chat-options-btn">
                <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bi bi-three-dots-vertical"></i>
                </button>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="bi bi-mic-mute"></i>Mute</a></li>
                  <li><a href="#"><i className="bi bi-slash-circle"></i>Ban</a></li>
                  <li><a href="#"><i className="bi bi-trash"></i>Remove</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
};

export default Doctors
