import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../utils/configs';
import cookie from 'react-cookies';

// Base URL for API
const API_URL = `${baseUrl}fitness`;

// Function to retrieve auth token (async token handling for flexibility)
const getAuthToken = () => cookie.load('_auth');

// Async thunk to fetch all Fitness records
export const fetchFitness = createAsyncThunk('fitness/fetchFitness', async (query, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${API_URL}/all`, {
      headers: {
        Authorization: token,
      },
      params: query,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Async thunk to add a new Fitness record
export const addFitness = createAsyncThunk('fitness/addFitness', async (newFitness, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    const response = await axios.post(`${API_URL}`, newFitness, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Async thunk to update an existing Fitness record
export const updateFitness = createAsyncThunk('fitness/updateFitness', async ({ id, updatedFitness }, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    const response = await axios.put(`${API_URL}/${id}`, updatedFitness, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Async thunk to delete an Fitness record
export const deleteFitness = createAsyncThunk('fitness/deleteFitness', async (id, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    await axios.delete(`${API_URL}/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    return id;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

const fitnessSlice = createSlice({
  name: 'fitness',
  initialState: {
    fitness: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Fitness
      .addCase(fetchFitness.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFitness.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.fitness = action.payload.data;
        state.error = null;
      })
      .addCase(fetchFitness.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Add Fitness
      .addCase(addFitness.fulfilled, (state, action) => {
        state.fitness.push(action.payload);
        state.error = null;
      })
      .addCase(addFitness.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })

      // Update Fitness
      .addCase(updateFitness.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.fitness.findIndex((fitness) => fitness._id === action.payload.data._id);
        if (index !== -1) {
          state.fitness[index] = action.payload.data;
        }
        state.error = null;
      })
      .addCase(updateFitness.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Delete Fitness
      .addCase(deleteFitness.fulfilled, (state, action) => {
        state.fitness = state.fitness.filter((fitness) => fitness._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteFitness.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export const fitnessReducer = fitnessSlice.reducer;
