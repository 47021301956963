import { format } from 'date-fns';
import React from 'react'
import '../../../assets/css/custom/FitnessCert.css'
import { Link } from 'react-router-dom';

const FitnessCertificate = React.forwardRef(({ data }, ref) => {

    return (
        <>
            <main ref={ref}>
                <table className="table table-striped table-responsive table-bordered table-sm">
                    <tbody>
                        <tr>
                            <td colSpan="4" className="text-center heading">FITNESS CERTIFICATE</td>
                        </tr>
                        <tr>
                            <td className="heading" colSpan="3">Referring Doctor/Hospital: <b>{data?.referer?.toUpperCase()}</b></td>
                            <td>Date: <b>{data?.date && format(data?.date, 'dd-MM-yyyy')}</b></td>
                        </tr>
                        <tr>
                            <td className="heading" colSpan="3">Patient's Name: <b>{data?.patient?.name?.toUpperCase()}</b></td>
                            <td>Age/Sex: <b>{`${data?.patient?.age}/${({ male: 'M', female: 'F' }[data?.patient?.sex] || 'O')}`}</b></td>
                        </tr>
                        <tr>
                            <td colSpan="2">Address: <b>{data?.patient?.city}</b></td>
                            <td colSpan="">UHID: <b>{data?.patient?.uhid}</b></td>
                            <td >Mobile: <b>{data?.patient?.mobile}</b></td>
                        </tr>
                        <tr>
                            <td className="heading" colSpan="4">Posted For: <b>{data?.postedFor?.toUpperCase()}</b></td>
                        </tr>
                        <tr>
                            <td rowSpan={4}>Complaints: </td>
                        </tr>
                        {Object.entries(data?.complaints || {})
                            .reduce((rows, [complaint, value], i) => {
                                // Start a new row every 3 items
                                if (i % 3 === 0) rows.push([]);
                                rows[rows.length - 1].push([complaint, value]);
                                return rows;
                            }, [])
                            .map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map(([complaint, value], i) => (
                                        <td key={i}>
                                            {complaint.replace(/_/g, ' ')} = {value ? "YES" : "NO"}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        }
                        <tr>
                            <td rowSpan={3}>Past History Of: </td>
                        </tr>
                        {Object.entries(data?.history || {})
                            .reduce((rows, [historyItem, value], i) => {
                                // Start a new row every 3 items
                                if (i % 3 === 0) rows.push([]);
                                rows[rows.length - 1].push([historyItem, value]);
                                return rows;
                            }, [])
                            .map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map(([historyItem, value], i) => (
                                        <td key={i}>
                                            {historyItem.replace(/_/g, ' ')} = {value ? "YES" : "NO"}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        }
                        <tr>
                            <td rowSpan={3}>Personal H/O: </td>
                        </tr>
                        {Object.entries(data?.personal || {})
                            .reduce((rows, [personalItem, value], i) => {
                                // Start a new row every 3 items
                                if (i % 3 === 0) rows.push([]);
                                rows[rows.length - 1].push([personalItem, value]);
                                return rows;
                            }, [])
                            .map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map(([personalItem, value], i) => (
                                        <td key={i}>
                                            {personalItem.replace(/_/g, ' ')} = {value ? "YES" : "NO"}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        }
                        <tr>
                            <td rowSpan={5}>General Examination: </td>
                        </tr>
                        {Object.entries(data?.examination || {})
                            .reduce((rows, [examinationItem, value], i) => {
                                // Start a new row every 3 items
                                if (i % 3 === 0) rows.push([]);
                                rows[rows.length - 1].push([examinationItem, value]);
                                return rows;
                            }, [])
                            .map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map(([examinationItem, value], i) => (
                                        <td key={i}>
                                            {examinationItem.replace(/_/g, ' ')} = {typeof value === "boolean" ? (value ? "YES" : "NO") : value}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        }
                        <tr>
                            <td rowSpan={3}>Systemic Examination: </td>
                        </tr>
                        <tr>
                            <td>RS = BILATERAL CLEAR</td>
                            <td>CVS = S1 , S2 NORMAL</td>
                            <td>P/A = SOFT NON TENDER</td>
                        </tr>
                        <tr>
                            <td>CNS = CONSCIOUS</td>
                        </tr>
                        <tr>
                            <td colSpan={4}>
                                <div className="heading footer">
                                    Opinion: <b>{data?.opinion?.toUpperCase()}</b>
                                </div>
                                <div className="heading sign">DOCTOR'S SIGNATURE</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center heading" colSpan={4}>Proudly Powered By: <Link to="https://www.inshatech.com" target='_blank'>Insha Technologies</Link></td>
                        </tr>
                    </tbody>
                </table>
            </main>
        </>
    )
});

export default FitnessCertificate
