import { vitals } from "../../utils/constants"

const PatientVitals = ({formik}) => {
    return (
        <>
            <div className="row g-lg-2 mb-0">
                {/* Map through the vitals object to generate 2x2 input grid */}
                {Object.entries(vitals).map(([key, value], index) => (
                    <div key={key} className="col-md-6">
                        <div className="form-group mb-3">
                            <label className="form-label" htmlFor={`vitals.${key}`}>{key.replace("_", " ")}</label>
                            <input
                                type="text"
                                id={`vitals.${key}`}
                                name={`vitals.${key}`}
                                className="form-control"
                                placeholder={key.replace('_', ' ')}
                                value={formik.values.vitals[key] || value}
                                onChange={formik.handleChange}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default PatientVitals