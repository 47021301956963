export const pageStep = (pathname) => {
  // Check if pathname is undefined, null, or empty
  if (!pathname) {
    return [false, "", ""];
  }

  // Split the string into parts and remove empty strings
  let parts = pathname.split("/").filter(Boolean);

  // Initialize variables
  let parentPage = false;
  let pageTitle = "";
  let pageUrl = "";

  // Check how many parts are available
  if (parts.length === 1) {
    // Only one part, so assign it to pageTitle
    pageTitle = parts[0].includes("-")
      ? parts[0].split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
      : parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
    pageUrl = parts[0];
  } else if (parts.length > 1) {
    // More than one part, process both
    parentPage = parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
    pageTitle = parts[1].includes("-")
      ? parts[1].split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
      : parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
    pageUrl = parts[1];
  }

  return [parentPage, pageTitle, pageUrl];
}
