import { format } from "date-fns";
import { useFormik } from "formik";
import { Accordion, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchIpds } from "../../store/slices/ipdSlice";
import { showAlertMessage } from "../../utils/sweetAlert";
import Select from 'react-select';
import { useEffect, useState } from "react";
import Button from "../../components/common/Button";
import { Link } from "react-router-dom";
import Discharge from "../../components/IPD/Discharge";
import Switch from "../../components/IPD/Switch";
import BedHistory from "./BedHistory";

const IPDList = () => {
  const dispatch = useDispatch();
  const [showDischargeModal, setShowDischargeModal] = useState(false);
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const { ipds, loading } = useSelector((state) => state.ipd);
  const sortedIpdsDesc = [...ipds].sort((a, b) => b.srNo - a.srNo);
  const [searchTerm, setSearchTerm] = useState(''); // Independent search state

  useEffect(() => {
    if (!showSwitchModal || !showDischargeModal) {
      const query = {
        doctor: selectedDoctor,
        is_admitted: true,
      };
      dispatch(fetchIpds(query));
    }
  }, [dispatch, selectedDoctor, showSwitchModal, showDischargeModal]);

  // handle bed history OffCanvas
  const [bedHistData, setBedHistData] = useState([]);
  const handleBedHistory = async (data) => {
    setShowOffCanvas(true);
    setBedHistData(data);
  }

  // handle discharge modal
  const [dischargeData, setDischargeData] = useState([]);
  const handleDischarge = async (data) => {
    setShowDischargeModal(true);
    setDischargeData(data);
  }

  // handle switch modal
  const [switchData, setSwitchData] = useState([]);
  const handleSwitch = async (data) => {
    setShowSwitchModal(true);
    setSwitchData(data);
  }

  // Select doctor
  const doctorInfo = useSelector((state) => state.doctor.doctorDetails);
  const [doctor, setDoctor] = useState([]);

  useEffect(() => {
    if (doctorInfo) {
      setDoctor(doctorInfo.map((item) => ({ value: item._id, label: item.name })));
    }
  }, [doctorInfo]);

  // Fetch IPD records

  const handleDoctorChange = (selectedOption) => {
    formik.setFieldValue('doctor', selectedOption ? selectedOption.value : '');
    formik.setFieldTouched('doctor', true);
    setSelectedDoctor(selectedOption.value);
    const query = {
      doctor: selectedOption.value,
      is_admitted: true,
    };
    dispatch(fetchIpds(query));
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      doctor: '',
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
    },
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      try {
        dispatch(fetchIpds(values));
      } catch (error) {
        showAlertMessage({
          icon: 'error',
          title: 'Error occurred!',
          text: error.message,
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  const filteredIpds = sortedIpdsDesc.filter((ipd) => {
    return (
      ipd?.patient?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ipd?.patient?.mobile.includes(searchTerm) ||
      ipd?.patient?.uhid.includes(searchTerm)
    );
  });

  return (
    <>
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="element-heading">
            <h6>Select Doctor</h6>
          </div>
        </div>
        <div className="container mb-3">
          <div className="card">
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <Select
                    name="doctor"
                    placeholder="Select Doctor"
                    options={doctor}
                    value={doctor.find((option) => option.value === formik.values.doctor)}
                    onChange={handleDoctorChange}
                    autoFocus
                  />
                </div>
                <hr />
                <Accordion>
                  <Accordion.Item eventKey="0" style={{ border: "3px solid #CFE2FF" }}>
                    <Accordion.Header>Search Criteria</Accordion.Header>
                    <Accordion.Body>
                      <div className="row g-lg-2 mb-0">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="date"
                              id="startDate"
                              name="startDate"
                              className="form-control"
                              value={formik.values.startDate}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="date"
                              id="endDate"
                              name="endDate"
                              className="form-control"
                              value={formik.values.endDate}
                              onChange={formik.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row g-lg-2 mb-0">
                        <div className="form-group">
                          <Button
                            type="submit"
                            className="btn btn-success w-100"
                            icon={formik.isSubmitting && <Spinner animation="border" size="sm" />}
                            disabled={formik.isSubmitting}
                            text={formik.isSubmitting ? "Processing..." : "Search"}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="row g-lg-2 mb-0">
                        <div className="form-group">
                          <input
                            type="text"
                            id="search"
                            name="search"
                            placeholder="Search by name, mobile, or UHID..."
                            className="form-control"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </form>
            </div>
          </div>
        </div>

        {/* Loading State */}
        {loading ? (
          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" size="lg" variant="primary" />
                </div>
              </div>
            </div>
          </div>
        ) : filteredIpds && filteredIpds.length > 0 ? (
          <>
            <div className="container">
              <div className="element-heading mt-3 inline">
                <h6>Available IPDs</h6>
                <i className="fa-solid fa-chart-simple text-info fw-bold"> {filteredIpds.length}</i>
              </div>
            </div>
            {filteredIpds.map((ipd, i) => (
              <div key={i} className="container mb-1">
                <div className="alert unread custom-alert-1 alert-dark bg-white">
                  <div className="alert-text w-100">
                    <div className="card-ipd-head">
                      <div className="text-danger fw-bold">{ipd.srNo}</div>
                      <div className="text-black">UHID: {ipd?.patient?.uhid}</div>
                      <div
                        className="text-danger fw-bold d-flex align-items-center"
                        onClick={() => handleBedHistory(ipd)}
                        style={{ cursor: 'pointer' }}
                      >
                        Bed No: {ipd?.bed?.name}
                        <span style={{ marginLeft: '8px', fontWeight: 'bold', fontSize: '16px' }}>→</span>
                      </div>
                      <div
                        className={`badge ${ipd.is_admitted ? 'bg-success' : 'bg-danger'} rounded-pill mb-2 d-inline-block`}
                      ></div>
                    </div>
                    <div className="ipd-body">
                      <div className="ipd-body-left">
                        <span className="text-info fw-bold">{ipd?.patient?.name}</span>
                        <span>DOA: {format(ipd.doa, 'dd-MM-yyyy hh:mm:ss a')}</span>
                        <span>DOD: {ipd?.dod && format(ipd?.dod, 'dd-MM-yyyy hh:mm:ss a')}</span>
                        <span className="text-danger">Note: {ipd?.notes}</span>
                      </div>
                      <div className="ipd-body-right">
                        <span className="text-info fw-bold">
                          {`${ipd?.patient?.age}/${({ male: 'M', female: 'F' }[ipd?.patient?.sex] || 'O')}`}
                        </span>
                        <span>{ipd?.patient?.mobile}</span>
                        <span>{ipd?.patient?.city}</span>
                      </div>
                    </div>
                    <div className="ipd-buttons">
                      <button hidden type="button"  disabled={!ipd?.is_admitted} onClick={() => handleDischarge(ipd)} className="btn btn-sm btn-success">
                        Discharge
                      </button>
                      <button type="button" disabled={!ipd?.is_admitted} onClick={() => handleSwitch(ipd)} className="btn btn-sm btn-dark">
                        Switch Bed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="container">
            <div className="card">
              <div className="card-body">
                <p className="text-center">No IPD records found.</p>
              </div>
            </div>
          </div>
        )}

      </div>
      <div className="add-new-contact-wrap">
        <Link to="/ipd/add">
          <i className="bi bi-plus-lg"></i>
        </Link>
      </div>
      <Discharge showDischargeModal={showDischargeModal} setShowDischargeModal={setShowDischargeModal} data={dischargeData} />
      <Switch showSwitchModal={showSwitchModal} setShowSwitchModal={setShowSwitchModal} data={switchData} />
      <BedHistory showOffCanvas={showOffCanvas} setShowOffCanvas={setShowOffCanvas} data={bedHistData} />
    </>
  );
};

export default IPDList;
