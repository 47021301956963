import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import cookie from 'react-cookies'
import { baseUrl } from '../../utils/configs';

const AuthToken = await cookie.load('_auth')

// Async thunk to handle get user information
export const getUserById = createAsyncThunk(
  'auth/getUserById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}user/${id}`, {
        headers: {
          Authorization: AuthToken,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const putUserById = createAsyncThunk(
  'auth/putUserById',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${baseUrl}user/${id}`, data, {
        headers: {
          Authorization: AuthToken,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoading: false,
    isError: false,
    userDetails: []
  },
  reducers: {
    userDetails(state, action) {
      state.userDetails = action.payload;
      state._id = action.payload._id;
    }
  },
  extraReducers: (builder) => {
    builder
    // Get user by id
      .addCase(getUserById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action.payload.data;
        state.isError = false;
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        
      })
      // Put user by id
      .addCase(putUserById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(putUserById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userDetails = action.payload.data;
        state.isError = false;
      })
      .addCase(putUserById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        
      });
  },
});

export const { userDetails } = userSlice.actions;

export const userReducer = userSlice.reducer;