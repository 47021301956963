import { format } from 'date-fns';
import React from 'react';
import { Offcanvas } from 'react-bootstrap';

const BedHistory = ({ showOffCanvas, setShowOffCanvas, data }) => {
    // Sort the bedHistory array by punch in descending order
    const sortedBedHistory = data?.bedHistory?.slice().sort((a, b) => new Date(b.punch) - new Date(a.punch)) || [];
    return (
        <Offcanvas show={showOffCanvas} onHide={() => setShowOffCanvas(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Bed History</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <table className="table table-striped table-responsive table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>Sr.</th>
                            <th>Bed</th>
                            <th>Check In</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedBedHistory.length > 0 ? (
                            sortedBedHistory.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.bed.name}</td>
                                    <td>{item.punch ? format(new Date(item.punch), 'dd-MM-yyyy hh:mm a') : ''}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3">No history available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default BedHistory;
