import { format } from 'date-fns';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { showAlertMessage } from '../utils/sweetAlert';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Spinner } from 'react-bootstrap';
import Button from '../components/common/Button';
import { fetchScalps } from '../store/slices/scalpSlice';
import { Link } from 'react-router-dom';
import Discharge from '../components/IPD/Discharge';
import AddScalp from '../components/scalp/AddScalp';

const Scalp = () => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const { scalps = [], loading } = useSelector((state) => state.scalp);
    const sortedScalps = [...scalps].sort((a, b) => new Date(b.doa) - new Date(a.doa));
    const [showDischargeModal, setShowDischargeModal] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [showAddScalpModal, setShowAddScalpModal] = useState(false);

    const filteredScalps = sortedScalps.filter(item =>
        item.patient?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.patient?.mobile?.includes(searchTerm) ||
        item.patient?.uhid?.includes(searchTerm)
    );

    useEffect(() => {
        if (!showDischargeModal || !showAddScalpModal) {
            const query = {
                doctor: selectedDoctor,
                is_admitted: true,
            };
            dispatch(fetchScalps(query));
        }
    }, [dispatch, selectedDoctor, showDischargeModal, showAddScalpModal]);

    // Select doctor
    const doctorInfo = useSelector((state) => state.doctor.doctorDetails);
    const [doctor, setDoctor] = useState([]);

    useEffect(() => {
        if (doctorInfo) {
            setDoctor(doctorInfo.map((item) => ({ value: item._id, label: item.name })));
        }
    }, [doctorInfo]);

    // Handle doctor change
    const handleDoctorChange = (selectedOption) => {
        formik.setFieldValue('doctor', selectedOption ? selectedOption.value : '');
        formik.setFieldTouched('doctor', true);
        setSelectedDoctor(selectedOption.value);
        const query = {
            doctor: selectedOption.value,
            is_admitted: true,
        };
        dispatch(fetchScalps(query));
    };

    // Handle discharge
    const [dischargeData, setDischargeData] = useState([]);
    const handleDischarge = async (data) => {
        setShowDischargeModal(true);
        setDischargeData(data);
    }

    // Formik setup
    const formik = useFormik({
        initialValues: {
            doctor: '',
            startDate: format(new Date(), 'yyyy-MM-dd'),
            endDate: format(new Date(), 'yyyy-MM-dd'),
        },
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            try {
                dispatch(fetchScalps(values));
            } catch (error) {
                showAlertMessage({
                    icon: 'error',
                    title: 'Error occurred!',
                    text: error.message,
                });
            } finally {
                actions.setSubmitting(false);
            }
        },
    });

    return (
        <>
            <div className="page-content-wrapper py-3">
                <div className="container">
                    <div className="element-heading">
                        <h6>Select Doctor</h6>
                    </div>
                </div>
                <div className="container mb-3">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <Select
                                        name="doctor"
                                        placeholder="Select Doctor"
                                        options={doctor}
                                        value={doctor.find((option) => option.value === formik.values.doctor)}
                                        onChange={handleDoctorChange}
                                        autoFocus
                                    />
                                </div>
                                <hr />
                                <Accordion>
                                    <Accordion.Item eventKey="0" style={{ border: "3px solid #CFE2FF" }}>
                                        <Accordion.Header>Search Criteria</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row g-lg-2 mb-0">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="date"
                                                            id="startDate"
                                                            name="startDate"
                                                            className="form-control"
                                                            value={formik.values.startDate}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="date"
                                                            id="endDate"
                                                            name="endDate"
                                                            className="form-control"
                                                            value={formik.values.endDate}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row g-lg-2 mb-0">
                                                <div className="form-group">
                                                    <Button
                                                        type="submit"
                                                        className="btn btn-success w-100"
                                                        icon={formik.isSubmitting && <Spinner animation="border" size="sm" />}
                                                        disabled={formik.isSubmitting}
                                                        text={formik.isSubmitting ? "Processing..." : "Search"}
                                                    />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row g-lg-2 mb-0">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        id="search"
                                                        name="search"
                                                        placeholder="Search by name, mobile, or UHID..."
                                                        className="form-control"
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </form>
                        </div>
                    </div>
                </div>
                {/* Loading State */}
                {loading ? (
                    <div className="container">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" size="lg" variant="primary" />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : filteredScalps && filteredScalps.length > 0 ? (
                    <>
                        <div className="container">
                            <div className="element-heading mt-3 inline">
                                <h6>Available scalps</h6>
                                <i className="fa-solid fa-chart-simple text-info fw-bold"> {filteredScalps?.length}</i>
                            </div>
                        </div>
                        {filteredScalps.map((item, i) => (
                            <div key={i} className="container mb-1">
                                <div className="alert unread custom-alert-1 alert-dark bg-white">
                                    <div className="alert-text w-100">
                                        <div className="card-ipd-head">
                                            <div className="text-danger fw-bold">{item?.srNo}</div>
                                            <div className="text-black">UHID: {item?.patient?.uhid}</div>
                                            <div
                                                className={`badge ${item?.is_admitted ? 'bg-success' : 'bg-danger'} rounded-pill mb-2 d-inline-block`}
                                            ></div>
                                            <span className="text-info fw-bold">
                                                {`${item?.patient?.age}/${({ male: 'M', female: 'F' }[item?.patient?.sex] || 'O')}`}
                                            </span>
                                        </div>
                                        <div className="ipd-body">
                                            <div className="ipd-body-left">
                                                <span className="text-info fw-bold">{item?.patient?.name}</span>
                                                <span>A: {item?.doa && format(item?.doa, 'dd-MM-yyyy hh:mm:ss a')}</span>
                                                <span>D: {item?.dod && format(item?.dod, 'dd-MM-yyyy hh:mm:ss a')}</span>
                                                <span>T: {item?.createdAt && format(item?.createdAt, 'dd-MM-yyyy hh:mm:ss a')}</span>
                                            </div>
                                            <div className="ipd-body-right">
                                                <span>{item?.patient?.city}</span>
                                                <span>{item?.patient?.mobile}</span>
                                                <span>Fee: Rs {item?.fees}/-</span>
                                            </div>
                                        </div>
                                        <div className="ipd-buttons">
                                            {/* <button type="button"
                                                onClick={() => handleUpdate(item._id)}
                                                className="btn btn-sm btn-success">
                                                Update
                                            </button> */}
                                            <button
                                                type="button"
                                                disabled={!item?.is_admitted}
                                                onClick={() => handleDischarge(item)}
                                                className="btn btn-sm btn-dark">
                                                Discharge
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="container">
                        <div className="card">
                            <div className="card-body">
                                <p className="text-center">No scalps found.</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="add-new-contact-wrap">
                <Link to="#" onClick={() => setShowAddScalpModal(true)}>
                    <i className="bi bi-plus-lg"></i>
                </Link>
            </div>
            <Discharge showDischargeModal={showDischargeModal} setShowDischargeModal={setShowDischargeModal} data={dischargeData} />
            <AddScalp showAddScalpsModal={showAddScalpModal} setShowAddScalpsModal={setShowAddScalpModal} />

        </>
    )
}

export default Scalp
