import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/authSlice";
import { userReducer } from "./slices/userSlice";
import { doctorReducer } from "./slices/doctorSlice";
import { patientReducer } from "./slices/patientSlice";
import { opdReducer } from "./slices/opdSlice";
import { bedReducer } from "./slices/bedSlice";
import { ipdReducer } from "./slices/ipdSlice";
import { fitnessReducer } from "./slices/fitnessSlice";
import { fitnessSearchReducer } from "./slices/fitnessSearchSlice";
import { dischargeSearchReducer } from "./slices/dischargeSearchSlice";
import { dischargeCertReducer } from "./slices/dischargeCertSlice";
import { scalpReducer } from "./slices/scalpSlice";
import { opdSearchReducer } from "./slices/opdSearchSlice";

const ReduxStore = configureStore({
  reducer:{
    auth: authReducer,
    user: userReducer,
    doctor: doctorReducer,
    patient: patientReducer,
    opd: opdReducer,
    bed: bedReducer,
    ipd: ipdReducer,
    fitness: fitnessReducer,
    fitnessSearch: fitnessSearchReducer,
    dischargeSearch: dischargeSearchReducer,
    dischargeCert: dischargeCertReducer,
    scalp: scalpReducer,
    opdSearch : opdSearchReducer,
  }
});

export default ReduxStore
