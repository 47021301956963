import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { showAlertMessage } from '../../utils/sweetAlert';
import * as Yup from 'yup';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatients } from '../../store/slices/patientSlice';
import { fetchFitnessSearch } from '../../store/slices/fitnessSearchSlice';
import { Accordion, Spinner } from 'react-bootstrap';
import Button from '../../components/common/Button';
import Select from 'react-select';
import { format } from 'date-fns';
import { useLocation, useParams } from 'react-router-dom';
import { addFitness, fetchFitness, updateFitness } from '../../store/slices/fitnessSlice';

const AddFitness = () => {
    const dispatch = useDispatch();
    const { options, isLoading } = useSelector((state) => state.patient);
    const { fitnessOptions } = useSelector((state) => state.fitnessSearch);
    const staff_id = localStorage.getItem('_Auth_id');
    const { pathname } = useLocation();
    const [singleFitness, setSingleFitness] = useState(null);

    //Fetch Fitness
    const { fitness } = useSelector((state) => state.fitness);
    const { id } = useParams();

    useEffect(() => {
        if (pathname !== '/fitness/add') {
            const query = {
                _id: id
            }
            dispatch(fetchFitness(query));
        }
    }, [pathname, dispatch, id]);

    useEffect(() => {
        if (fitness.length > 0) {
            if (pathname !== '/fitness/add') {
                setSingleFitness(fitness[0]);
            }
        }
    }, [fitness, pathname]);

    // Select doctor
    const doctorInfo = useSelector((state) => state.doctor.doctorDetails);
    const [doctor, setDoctor] = useState([]);

    useEffect(() => {
        if (doctorInfo) {
            setDoctor([{ value: '', label: 'Select a doctor' }, ...doctorInfo.map((item) => ({ value: item._id, label: item.name }))]);
        }
    }, [doctorInfo]);

    // Search for patients
    const handleSearch = (query) => {
        dispatch(fetchPatients(query));
    };

    // Search for referers
    const handleSearchReferer = (query) => {
        dispatch(fetchFitnessSearch(query));
    };

    // Formik setup
    const formik = useFormik({
        initialValues: {
            referer: singleFitness?.referer || '',
            name: singleFitness?.patient?.name || '',
            patient: singleFitness?.patient?._id || '',
            postedFor: singleFitness?.postedFor || '',
            complaints: {
                Chest_Pain: singleFitness?.complaints?.Chest_Pain || false,
                Palpitation: singleFitness?.complaints?.Palpitation || false,
                Breathlessness: singleFitness?.complaints?.Breathlessness || false,
                Cough: singleFitness?.complaints?.Cough || false,
                Dyspnea_on_Exertion: singleFitness?.complaints?.Dyspnea_on_Exertion || false,
                Angina_on_Exertion: singleFitness?.complaints?.Angina_on_Exertion || false,
                Prolonged_Fever: singleFitness?.complaints?.Prolonged_Fever || false,
            },
            history: {
                HTN: singleFitness?.history?.HTN || false,
                DM: singleFitness?.history?.DM || false,
                CAD_CVA: singleFitness?.history?.CAD_CVA || false,
                KOCH: singleFitness?.history?.KOCH || false,
                CKD: singleFitness?.history?.CKD || false,
                Asthma_COPD: singleFitness?.history?.Asthma_COPD || false,
            },
            personal: {
                Tobacco: singleFitness?.personal?.Tobacco || false,
                Bidi_Cigarette: singleFitness?.personal?.Bidi_Cigarette || false,
                Alcohol: singleFitness?.personal?.Alcohol || false,
                H_O_Hospitalization: singleFitness?.personal?.H_O_Hospitalization || false,
                Any_Drug_Reaction: singleFitness?.personal?.Any_Drug_Reaction || false,
            },
            examination: {
                BP: singleFitness?.examination?.BP || '',
                Pulse: singleFitness?.examination?.Pulse || '',
                Blood_Sugar: singleFitness?.examination?.Blood_Sugar || '',
                ECG: singleFitness?.examination?.ECG || 'NORMAL',
                Temp: singleFitness?.examination?.Temp || 'AFEBRILE',
                Pallor: singleFitness?.examination?.Pallor || false,
                Icterus: singleFitness?.examination?.Icterus || false,
                Edema_Feet: singleFitness?.examination?.Edema_Feet || false,
                Lap: singleFitness?.examination?.Lap || false,
                Clubbing: singleFitness?.examination?.Clubbing || false,
                Cyanosis: singleFitness?.examination?.Cyanosis || false,
            },
            staff: staff_id,
            doctor: singleFitness?.doctor?._id || '',
            date: format(singleFitness?.date || new Date(), 'yyyy-MM-dd'),
            opinion: singleFitness?.opinion || '',
            other: {},
        },
        validationSchema: Yup.object({
            referer: Yup.string().required('Referer is required'),
            doctor: Yup.string().required('Doctor is required'),
            name: Yup.string().required('Patient name is required'),
            examination: Yup.object({
                BP: Yup.string().required('Blood Pressure is required'),
                Pulse: Yup.string().required('Pulse is required'),
                Blood_Sugar: Yup.string().required('Blood Sugar is required'),
                ECG: Yup.string().required('ECG is required'),
                Temp: Yup.string().required('Temperature is required'),
                Pallor: Yup.boolean(),
                Icterus: Yup.boolean(),
                Edema_Feet: Yup.boolean(),
                Lap: Yup.boolean(),
                Clubbing: Yup.boolean(),
                Cyanosis: Yup.boolean(),
            }),
        }),
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            try {
                let response;
                if (pathname !== '/fitness/add') {
                    response = await dispatch(updateFitness({ id: singleFitness._id, updatedFitness: values }));
                } else {
                    response = await dispatch(addFitness(values))
                }
                if (response?.payload?.success) {
                    await showAlertMessage({ text: response?.payload?.message });
                    actions.resetForm();
                } else {
                    throw new Error(response?.payload?.message);
                }
            } catch (error) {
                showAlertMessage({
                    icon: 'error',
                    title: 'Error occurred!',
                    text: error.message,
                });
            } finally {
                actions.setSubmitting(false);
            }
        },
    });

    return (
        <div className="page-content-wrapper py-3">
            <div className="container">
                <div className="element-heading">
                    <h6>General Information</h6>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="container mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="row g-lg-2 mb-0">
                                <div className="form-group mb-3">
                                    <label className="form-label" htmlFor="doctor">Doctor</label>
                                    <Select
                                        name="doctor"
                                        placeholder="Doctor"
                                        options={doctor}
                                        value={doctor.find(option => option.value === formik.values.doctor)}
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue('doctor', selectedOption ? selectedOption.value : '');
                                            formik.setFieldTouched('doctor', true);
                                        }}
                                        autoFocus
                                    />

                                    {formik.touched.doctor && formik.errors.doctor ? (
                                        <div className="text-danger mb-1">{formik.errors.doctor}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row g-lg-2 mb-0">
                                <div className="form-group mb-3">
                                    <label className="form-label" htmlFor="Patient">Patient</label>
                                    <AsyncTypeahead
                                        filterBy={() => true}
                                        id="async-patient-search"
                                        isLoading={isLoading}
                                        labelKey="name"
                                        minLength={3}
                                        onSearch={handleSearch}
                                        options={options}
                                        placeholder="Patient..."
                                        selected={formik.values.name ? [{ name: formik.values.name }] : []}
                                        renderMenuItemChildren={(option) => (
                                            <>
                                                <span>{option.name}</span>
                                                {option.uhid && (
                                                    <>
                                                        <small> - {option.uhid}</small>
                                                        <p className="mb-0">{`${option.mobile} | ${option.age}/${option.sex === 'male' ? 'M' : option.sex === 'female' ? 'F' : 'O'} | ${option.city}`}</p>
                                                        <hr className="mt-1 mb-0" />
                                                    </>
                                                )}
                                            </>
                                        )}
                                        onChange={(selected) => {
                                            if (selected.length > 0) {
                                                formik.setFieldValue('name', selected[0].name);
                                                formik.setFieldValue('patient', selected[0]._id);
                                            }
                                        }}
                                    />
                                </div>
                                {formik.touched.patient && formik.errors.patient ? (
                                    <div className="text-danger mb-1">{formik.errors.patient}</div>
                                ) : null}
                            </div>
                            <hr />
                            <div className="row g-lg-2 mb-2">
                                <div className="form-group mb-1">
                                    <label className="form-label" htmlFor="referer">Referer</label>
                                    <AsyncTypeahead
                                        // filterBy={() => true}
                                        id="referer"
                                        isLoading={isLoading}
                                        labelKey="referer"
                                        minLength={2}
                                        onSearch={handleSearchReferer}
                                        options={fitnessOptions || []}
                                        placeholder="Doctor / Hospital"
                                        allowNew={true}
                                        selected={formik.values.referer ? [{ referer: formik.values.referer }] : []}
                                        renderMenuItemChildren={(option) => (
                                            <span>{option.referer}</span>
                                        )}
                                        onChange={(selected) => {
                                            const value = selected.length > 0 ? selected[0]?.referer : formik.values.referer;
                                            formik.setFieldValue('referer', value);
                                        }}
                                    />
                                </div>
                                {formik.touched.referer && formik.errors.referer ? (
                                    <div className="text-danger mb-1">{formik.errors.referer}</div>
                                ) : null}
                            </div>
                            <div className="row g-lg-2 mb-0">
                                <div className="col">
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="date">Date</label>
                                        <input
                                            name="date"
                                            id="date"
                                            className="form-control"
                                            type="date"
                                            placeholder="Date"
                                            onChange={formik.handleChange}
                                            value={formik.values.date}
                                        />
                                    </div>
                                    {formik.touched.date && formik.errors.date ? (
                                        <div className="text-danger mb-1">{formik.errors.date}</div>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="postedFor">Posted For</label>
                                        <AsyncTypeahead
                                            id="postedFor"
                                            isLoading={isLoading}
                                            labelKey="postedFor"
                                            minLength={2}
                                            onSearch={handleSearchReferer}
                                            options={fitnessOptions || []}
                                            placeholder="Posted For"
                                            allowNew={true}
                                            selected={formik.values.postedFor ? [{ postedFor: formik.values.postedFor }] : []}
                                            renderMenuItemChildren={(option) => (
                                                <span>{option.postedFor}</span>
                                            )}
                                            onChange={(selected) => {
                                                const value = selected.length > 0 ? selected[0]?.postedFor : formik.values.postedFor;
                                                formik.setFieldValue('postedFor', value);
                                            }}
                                        />
                                        {formik.touched.postedFor && formik.errors.postedFor ? (
                                            <div className="text-danger mb-1">{formik.errors.postedFor}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mb-3">
                    <div className="element-heading">
                        <h6>Patient Condition</h6>
                    </div>
                    <div className="card">
                        {/* <div className="card-body"> */}
                        <Accordion>
                            {/* Complaints Section */}
                            <Accordion.Item eventKey="0" style={{ border: "3px solid #CFE2FF" }}>
                                <Accordion.Header>Complaints?</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row g-lg-2 mb-0">
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="Chest_Pain"
                                                    type="checkbox"
                                                    name="complaints.Chest_Pain"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.complaints.Chest_Pain}
                                                />
                                                <label className="form-check-label" htmlFor="Chest_Pain">Chest Pain</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="Palpitation"
                                                    type="checkbox"
                                                    name="complaints.Palpitation"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.complaints.Palpitation}
                                                />
                                                <label className="form-check-label" htmlFor="Palpitation">Palpitation</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="Breathlessness"
                                                    type="checkbox"
                                                    name="complaints.Breathlessness"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.complaints.Breathlessness}
                                                />
                                                <label className="form-check-label" htmlFor="Breathlessness">Breathlessness</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-check">
                                        <input
                                            className="form-check-input form-check-danger"
                                            id="Prolonged_Fever"
                                            type="checkbox"
                                            name="complaints.Prolonged_Fever"
                                            onChange={formik.handleChange}
                                            checked={formik.values.complaints.Prolonged_Fever}
                                        />
                                        <label className="form-check-label" htmlFor="Prolonged_Fever">Prolonged Fever</label>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Medical History Section */}
                            <Accordion.Item eventKey="1" style={{ border: "3px solid #CFE2FF" }}>
                                <Accordion.Header>Medical History?</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row g-lg-2 mb-0">
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="HTN"
                                                    type="checkbox"
                                                    name="history.HTN"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.history.HTN}
                                                />
                                                <label className="form-check-label" htmlFor="HTN">HTN</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="DM"
                                                    type="checkbox"
                                                    name="history.DM"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.history.DM}
                                                />
                                                <label className="form-check-label" htmlFor="DM">DM</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="CAD_CVA"
                                                    type="checkbox"
                                                    name="history.CAD_CVA"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.history.CAD_CVA}
                                                />
                                                <label className="form-check-label" htmlFor="CAD_CVA">CAD/CVA</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-lg-2 mb-0">
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="KOCH"
                                                    type="checkbox"
                                                    name="history.KOCH"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.history.KOCH}
                                                />
                                                <label className="form-check-label" htmlFor="KOCH">KOCH</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="CKD"
                                                    type="checkbox"
                                                    name="history.CKD"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.history.CKD}
                                                />
                                                <label className="form-check-label" htmlFor="CKD">CKD</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="Asthma_COPD"
                                                    type="checkbox"
                                                    name="history.Asthma_COPD"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.history.Asthma_COPD}
                                                />
                                                <label className="form-check-label" htmlFor="Asthma_COPD">Asthma/COPD</label>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Personal History Section */}
                            <Accordion.Item eventKey="2" style={{ border: "3px solid #CFE2FF" }}>
                                <Accordion.Header>Personal History?</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row g-lg-2 mb-0">
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="Tobacco"
                                                    type="checkbox"
                                                    name="personal.Tobacco"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.personal.Tobacco}
                                                />
                                                <label className="form-check-label" htmlFor="Tobacco">Tobacco</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="Bidi_Cigarette"
                                                    type="checkbox"
                                                    name="personal.Bidi_Cigarette"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.personal.Bidi_Cigarette}
                                                />
                                                <label className="form-check-label" htmlFor="Bidi_Cigarette">Bidi/Cigarette</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="Alcohol"
                                                    type="checkbox"
                                                    name="personal.Alcohol"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.personal.Alcohol}
                                                />
                                                <label className="form-check-label" htmlFor="Alcohol">Alcohol</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-lg-2 mb-0">
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="H_O_Hospitalization"
                                                    type="checkbox"
                                                    name="personal.H_O_Hospitalization"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.personal.H_O_Hospitalization}
                                                />
                                                <label className="form-check-label" htmlFor="H_O_Hospitalization">H/O Hospitalization</label>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-danger"
                                                    id="Any_Drug_Reaction"
                                                    type="checkbox"
                                                    name="personal.Any_Drug_Reaction"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.personal.Any_Drug_Reaction}
                                                />
                                                <label className="form-check-label" htmlFor="Any_Drug_Reaction">Any Drug Reaction</label>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Examination and Investigations  Section */}
                            <Accordion.Item eventKey="3" style={{ border: "3px solid #CFE2FF" }}>
                                <Accordion.Header>Examination and Investigations?</Accordion.Header>
                                <Accordion.Body>
                                    <div className="row g-lg-2 mb-0">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="BP">Blood Pressure</label>
                                                <input
                                                    name="examination.BP"
                                                    id="BP"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Blood Pressure"
                                                    value={formik.values.examination.BP}
                                                    onChange={formik.handleChange}
                                                    required
                                                />
                                                {formik.errors.examination?.BP && <div className="text-danger">{formik.errors.examination.BP}</div>}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Pulse">Pulse</label>
                                                <input
                                                    name="examination.Pulse"
                                                    id="Pulse"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Pulse"
                                                    value={formik.values.examination.Pulse}
                                                    onChange={formik.handleChange}
                                                    required
                                                />
                                                {formik.errors.examination?.Pulse && <div className="text-danger">{formik.errors.examination.Pulse}</div>}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Blood_Sugar">Blood Sugar</label>
                                                <input
                                                    name="examination.Blood_Sugar"
                                                    id="Blood_Sugar"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Blood Sugar"
                                                    value={formik.values.examination.Blood_Sugar}
                                                    onChange={formik.handleChange}
                                                    required
                                                />
                                                {formik.errors.examination?.Blood_Sugar && <div className="text-danger">{formik.errors.examination.Blood_Sugar}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-lg-2 mb-0">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="ECG">ECG</label>
                                                <input
                                                    name="examination.ECG"
                                                    id="ECG"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="ECG"
                                                    value={formik.values.examination.ECG}
                                                    onChange={formik.handleChange}
                                                    required
                                                />
                                                {formik.errors.examination?.ECG && <div className="text-danger">{formik.errors.examination.ECG}</div>}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Temp">Temp.</label>
                                                <input
                                                    name="examination.Temp"
                                                    id="Temp"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Temp."
                                                    value={formik.values.examination.Temp}
                                                    onChange={formik.handleChange}
                                                    required
                                                />
                                                {formik.errors.examination?.Temp && <div className="text-danger">{formik.errors.examination.Temp}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row g-lg-2 mb-0">
                                        {['Pallor', 'Icterus', 'Edema_Feet', 'LAP', 'Clubbing', 'Cyanosis'].map((item, index) => (
                                            <div className="col" key={index}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        id={item}
                                                        type="checkbox"
                                                        name={`examination.${item}`}
                                                        checked={formik.values.examination[item]}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor={item}>{item}</label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        {/* </div> */}
                    </div>
                </div>
                <div className="container mb-3">
                    <div className="element-heading">
                        <h6>Opinion</h6>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3">
                                <label className="form-label" htmlFor="opinion">Opinion</label>
                                <AsyncTypeahead
                                    id="opinion"
                                    isLoading={isLoading}
                                    labelKey="opinion"
                                    minLength={2}
                                    onSearch={handleSearchReferer}
                                    options={fitnessOptions || []}
                                    placeholder="Opinion"
                                    allowNew={true}
                                    selected={formik.values.opinion ? [{ opinion: formik.values.opinion }] : []}
                                    renderMenuItemChildren={(option) => (
                                        <span>{option.opinion}</span>
                                    )}
                                    onChange={(selected) => {
                                        const value = selected.length > 0 ? selected[0]?.opinion : formik.values.opinion;
                                        formik.setFieldValue('opinion', value);
                                    }}
                                />
                                {formik.touched.opinion && formik.errors.opinion ? (
                                    <div className="text-danger mb-1">{formik.errors.opinion}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="row g-lg-2 mb-0">
                                <div className="form-group mb-3">
                                    <Button type="submit" className="btn btn-success w-100" icon={formik.isSubmitting && <Spinner animation="border" size="sm" />} disabled={formik.isSubmitting} text={formik.isSubmitting ? "Processing..." : "Save Changes"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddFitness;
