import { appName } from "../../utils/configs";
import { Link, useNavigate } from "react-router-dom";

const Header = ({ toggleMenu, pageTitle }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  return (
    <div className="header-area" id="headerArea">
      <div className="container">
        <div className="header-content header-style-five position-relative d-flex align-items-center justify-content-between">
          {
            pageTitle === 'HOME' ?
              <>
                <div className="logo-wrapper">
                  <Link to="/" className="text-black strong">
                    <h5>{appName}</h5>
                  </Link>
                </div>
              </> :
              <>
                <div className="back-button">
                  <Link onClick={handleBack}>
                    <i className="bi bi-arrow-left-short"></i>
                  </Link>
                </div>
              </>
          }

          <div className="page-heading">
            <h6 className="mb-0">{pageTitle}</h6>
          </div>
          <div className="navbar--toggler" id="affanNavbarToggler" onClick={toggleMenu} data-bs-toggle="offcanvas" data-bs-target="#affanOffcanvas" aria-controls="affanOffcanvas">
            <span className="d-block"></span>
            <span className="d-block"></span>
            <span className="d-block"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
