import { useFormik } from "formik";
import { showAlertMessage } from "../../utils/sweetAlert";
import Select from 'react-select';
import { Button, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { fetchPatients } from "../../store/slices/patientSlice";
import { sex } from "../../utils/constants";
import { format } from "date-fns";
import { useLocation, useParams } from "react-router-dom";
import { addIpd, clearIpds, fetchIpds, updateIpd } from "../../store/slices/ipdSlice";
import { deleteBed, fetchBeds } from "../../store/slices/bedSlice";
import AddBed from "../../components/IPD/AddBed";

const AddIPD = () => {
  const dispatch = useDispatch();
  const staff_id = localStorage.getItem('_Auth_id');
  const [IPD, setIPD] = useState();
  const { pathname } = useLocation();

  //Fetch the OPD ------------------------------------------------------------
  const { ipds } = useSelector((state) => state.ipd);
  const { id } = useParams();

  useEffect(() => {
    if (pathname !== '/ipd/add') {
      const query = {
        _id: id
      }
      dispatch(fetchIpds(query));
    }
    dispatch(fetchBeds({ isOccupied: false }))
  }, [id, dispatch, pathname])

  useEffect(() => {
    if (ipds.success) {
      if (pathname !== '/ipd/add') {
        setIPD(ipds.data[0]);
      }
    }
  }, [ipds, pathname])

  // Fetching doctor details and mapping them for the Select component
  const doctorInfo = useSelector((state) => state.doctor.doctorDetails);
  const [doctorOptions, setDoctorOptions] = useState([]);

  useEffect(() => {
    if (doctorInfo) {
      setDoctorOptions([{ value: '', label: 'Select a doctor' }, ...doctorInfo.map((item) => ({ value: item._id, label: item.name }))]);
    }
  }, [doctorInfo]);

  // Fetching bed details and mapping them for the Select component
  const bedInfo = useSelector((state) => state.bed.beds);
  const [bedOptions, setBedOptions] = useState([]);

  useEffect(() => {
    if (bedInfo) {
      setBedOptions([{ value: '', label: 'Select a bed' }, ...bedInfo.map((item) => ({ value: item._id, label: item.name }))]);
    }
  }, [bedInfo]);


  // Handling patient search through AsyncTypeahead
  const { options: patientOptions, isLoading } = useSelector((state) => state.patient);
  const handlePatientSearch = (query) => {
    dispatch(fetchPatients(query));
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      doctor: '',
      name: '',
      patient: '',
      age: '',
      city: '',
      sex: '',
      mobile: '',
      doa: format(new Date(), 'yyyy-MM-dd\'T\'HH:mm'),
      bed: '',
      staff: staff_id,
    },
    validationSchema: Yup.object({
      doctor: Yup.string().required('Doctor selection is required'),
      name: Yup.string().required('Patient name is required'),
      age: Yup.string().required('Age is required'),
      city: Yup.string().required('Address is required'),
      sex: Yup.string().required('Gender is required'),
      mobile: Yup.string().matches(/^[6-9]\d{9}$/, 'Mobile number must be a valid 10-digit Indian number')
        .required('Mobile is required'),
      bed: Yup.string().required('Bed selection is required'),
    }),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      try {
        let response;
        if (pathname !== '/ipd/add') {
          response = await dispatch(updateIpd({ id: IPD?._id, updatedIpd: values }));
        } else {
          response = await dispatch(addIpd(values))
        }
        if (response?.payload?.success) {
          await showAlertMessage({ text: response?.payload?.message });
          // Dispatch fetchBeds after form reset
          dispatch(fetchBeds({ isOccupied: false }));
          dispatch(clearIpds());
          actions.resetForm();
        } else {
          throw new Error(response?.payload?.message);
        }
      } catch (error) {
        showAlertMessage({
          icon: 'error',
          title: 'Error occurred!',
          text: error.message,
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  //Modal dialog
  const [showBedModal, setShowBedModal] = useState(false);

  //Handle Delete Bed
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteBed = async () => {
    const _id = formik.values.bed || undefined;
    try {
      setDeleteLoading(true);
      if (_id === undefined) {
        throw new Error('Please select a bed to delete');
      }
      const response = await dispatch(deleteBed(_id))
      if (response?.payload?.success) {
        await dispatch(fetchBeds({ isOccupied: false }));
        await showAlertMessage({ text: response?.payload?.message });
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      setDeleteLoading(false);
    }

  }

  return (
    <>
      <AddBed showBedModal={showBedModal} setShowBedModal={setShowBedModal} />
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="element-heading">
            <h6>Select Doctor</h6>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="container mb-3">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label className="form-label" htmlFor="doctor">Doctor</label>
                  <Select
                    name="doctor"
                    placeholder="Doctor"
                    options={doctorOptions}
                    value={doctorOptions.find(option => option.value === formik.values.doctor)}
                    onChange={(selectedOption) => formik.setFieldValue('doctor', selectedOption ? selectedOption.value : '')}
                    autoFocus
                  />
                  {formik.touched.doctor && formik.errors.doctor ? (
                    <div className="text-danger mb-1">{formik.errors.doctor}</div>
                  ) : null}
                </div>
                <hr />
                <div className="form-group mb-3">
                  <AsyncTypeahead
                    id="async-patient-search"
                    isLoading={isLoading}
                    labelKey="name"
                    minLength={3}
                    onSearch={handlePatientSearch}
                    options={patientOptions}
                    placeholder="Search for a patient..."
                    selected={formik.values.name ? [{ name: formik.values.name }] : []}
                    renderMenuItemChildren={(option) => (
                      <>
                        <span>{option.name}</span>
                        {option.uhid && (
                          <>
                            <small> - {option.uhid}</small>
                            <p className="mb-0">{`${option.mobile} | ${option.age}/${option.sex === 'male' ? 'M' : option.sex === 'female' ? 'F' : 'O'} | ${option.city}`}</p>
                            <hr className="mt-1 mb-0" />
                          </>
                        )}
                      </>
                    )}
                    onChange={(selected) => {
                      if (selected.length > 0) {
                        const patient = selected[0];
                        formik.setFieldValue('name', patient.name);
                        formik.setFieldValue('patient', selected[0]._id);
                        formik.setFieldValue('age', patient.age || '');
                        formik.setFieldValue('city', patient.city || '');
                        formik.setFieldValue('sex', patient.sex || '');
                        formik.setFieldValue('mobile', patient.mobile || '');
                      }
                    }}
                  />
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div className="form-group mb-3">
                      <input
                        name="mobile"
                        className="form-control"
                        type="text"
                        placeholder="Mobile No."
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                      />
                    </div>
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className="text-danger mb-1">{formik.errors.mobile}</div>
                    ) : null}
                  </div>
                  <div className="col">
                    <div className="form-group mb-3">
                      <input
                        name="age"
                        className="form-control"
                        type="number"
                        placeholder="Age"
                        onChange={formik.handleChange}
                        value={formik.values.age}
                      />
                    </div>
                    {formik.touched.age && formik.errors.age ? (
                      <div className="text-danger mb-1">{formik.errors.age}</div>
                    ) : null}
                  </div>
                </div>

                {/* Sex and Address */}
                <div className="row mb-3">
                  <div className="col">
                    <div className="form-group mb-3">
                      <Select
                        name="sex"
                        placeholder="Gender"
                        options={sex}
                        value={sex.find(option => option.value === formik.values.sex)}
                        onChange={(selectedOption) => formik.setFieldValue('sex', selectedOption ? selectedOption.value : '')}
                      />
                    </div>
                    {formik.touched.sex && formik.errors.sex ? (
                      <div className="text-danger mb-1">{formik.errors.sex}</div>
                    ) : null}
                  </div>
                  <div className="col">
                    <div className="form-group mb-3">
                      <input
                        name="city"
                        className="form-control"
                        type="text"
                        placeholder="Address"
                        onChange={formik.handleChange}
                        value={formik.values.city}
                      />
                    </div>
                    {formik.touched.city && formik.errors.city ? (
                      <div className="text-danger mb-1">{formik.errors.city}</div>
                    ) : null}
                  </div>
                </div>
                <hr />
                {/* SR No. and Date of Admission */}
                <div className="row mb-3">
                  {/* Date of Admission */}
                  <div className="col-6">
                    <div className="form-group mb-3">
                      <input
                        name="doa"
                        className="form-control"
                        type="datetime-local"
                        onChange={formik.handleChange}
                        value={formik.values.doa}
                      />
                    </div>
                    {formik.touched.doa && formik.errors.doa ? (
                      <div className="text-danger mb-1">{formik.errors.doa}</div>
                    ) : null}
                  </div>

                  {/* Bed Selection and Buttons */}
                  <div className="col-6">
                    <div className="row">
                      {/* Bed Dropdown */}
                      <div className="col-8">
                        <Select
                          name="bed"
                          placeholder="Bed"
                          options={bedOptions}
                          value={bedOptions.find(option => option.value === formik.values.bed)}
                          onChange={(selectedOption) => formik.setFieldValue('bed', selectedOption ? selectedOption.value : '')}
                        />
                        {formik.touched.bed && formik.errors.bed ? (
                          <div className="text-danger mb-1">{formik.errors.bed}</div>
                        ) : null}
                      </div>

                      {/* Buttons */}
                      <div className="col">
                        <button className="btn m-1 btn-sm btn-success" type="button" onClick={() => setShowBedModal(true)}>
                          <i className="fa-solid fa-plus"></i>
                        </button>
                        <button className="btn m-1 btn-sm btn-danger" type="button" onClick={() => handleDeleteBed()} disabled={deleteLoading}>
                          {deleteLoading ? <Spinner animation="border" size="sm" /> : <i className="fa-solid fa-trash-can"></i>}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <Button type="submit" variant="success" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddIPD;
