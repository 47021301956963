import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../utils/configs';
import cookie from 'react-cookies';

// Base URL for API
const API_URL = `${baseUrl}discharge`;

// Function to retrieve auth token (async token handling for flexibility)
const getAuthToken = () => cookie.load('_auth');

// Async thunk to fetch all DischargeCert records
export const fetchDischargeCert = createAsyncThunk('dischargeCert/fetchDischargeCert', async (query, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${API_URL}/all`, {
      headers: {
        Authorization: token,
      },
      params: query,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Async thunk to add a new DischargeCert record
export const createDischargeCert = createAsyncThunk('dischargeCert/createDischargeCert', async (newDischargeCert, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    const response = await axios.post(`${API_URL}`, newDischargeCert, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Async thunk to update an existing DischargeCert record
export const updateDischargeCert = createAsyncThunk('dischargeCert/updateDischargeCert', async ({ id, updatedDischargeCert }, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    const response = await axios.put(`${API_URL}/${id}`, updatedDischargeCert, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

// Async thunk to delete an DischargeCert record
export const deleteDischargeCert = createAsyncThunk('dischargeCert/deleteDischargeCert', async (id, { rejectWithValue }) => {
  try {
    const token = getAuthToken();
    await axios.delete(`${API_URL}/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    return id;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
});

const dischargeCertSlice = createSlice({
  name: 'dischargeCert',
  initialState: {
    dischargeCert: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    clearDischargeCert: (state) => {
      state.dischargeCert = [];
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch DischargeCert
      .addCase(fetchDischargeCert.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDischargeCert.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dischargeCert = action.payload.data;
        state.error = null;
      })
      .addCase(fetchDischargeCert.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Add DischargeCert
      .addCase(createDischargeCert.fulfilled, (state, action) => {
        state.dischargeCert.push(action.payload);
        state.error = null;
      })
      .addCase(createDischargeCert.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })

      // Update DischargeCert
      .addCase(updateDischargeCert.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.dischargeCert.findIndex((dischargeCert) => dischargeCert._id === action.payload.data._id);
        if (index !== -1) {
          state.dischargeCert[index] = action.payload.data;
        }
        state.error = null;
      })
      .addCase(updateDischargeCert.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // Delete DischargeCert
      .addCase(deleteDischargeCert.fulfilled, (state, action) => {
        state.dischargeCert = state.dischargeCert.filter((dischargeCert) => dischargeCert._id !== action.payload);
        state.error = null;
      })
      .addCase(deleteDischargeCert.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      });
  },
});

export const { clearDischargeCert } = dischargeCertSlice.actions;
export const dischargeCertReducer = dischargeCertSlice.reducer;
