import React, { useRef } from 'react';
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import '../../../assets/css/custom/Prescription.css';

const PrescriptionHeaderPrint = React.forwardRef(({ data }, ref) => {
  return (
    <main className='main' ref={ref}>
      <div className="top_header mb-3">
        <div className="left">
          <h5>{data?.srNo}:{`${data?.patient?.name?.toUpperCase()} (${data?.patient?.age}/${({ male: 'M', female: 'F' }[data?.patient?.sex] || 'O')})`}</h5>
          <h5>{data?.patient?.city?.toUpperCase()}/{data?.patient?.mobile}</h5>
        </div>
        <div className="right">
          <div className='data'>{data?.createdAt && format(new Date(data?.createdAt), 'dd-MM-yyyy hh:mm a')}</div>
          <div className='data'>HUID: {data?.patient?.uhid}</div>
        </div>
      </div>
      <div className="cve mb-3">
        <div className="complaints">
          <h5>CHIEF COMPLAINTS: </h5>
          <div className='data'>
            {data?.complaints?.split(',').map((complaint, index) => (
              <div key={index}>{complaint.trim()}</div>
            ))}
          </div>
        </div>
        <div className="vitals line-before">
          <h5>VITALS: </h5>
          <div className="data">BP(mm of HG): {data?.vitals?.Blood_Pressure}</div>
          <div className="data">PR(bpm): {data?.vitals?.Plus_Rate}</div>
          <div className="data">SPO2(%): {data?.vitals?.Spo2}</div>
          <div className="data">SUGAR(mg/dl): {data?.vitals?.Sugar}</div>
        </div>
        <div className="examination line-before">
          <h5>EXAMINATION: </h5>
          <div className="data">
            {data?.examination?.split(',').map((examination, index) => (
              <div key={index}>{examination.trim()}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="hd">
        <div className='heading'>PAST MEDICAL HISTORY: {data?.history}</div>
        <div className='heading'>DIAGNOSIS: {data?.diagnosis}</div>
        <div className='data'>Rx</div>
      </div>
      <hr />
    </main>
  );
});

const PrescriptionHeader = () => {
  const componentRef = useRef();
  const location = useLocation();
  const data = location.state?.data;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Prescription - ${data?.patient?.uhid} - ${data?.createdAt && format(new Date(data?.createdAt), 'dd-MM-yyyy hh:mm a')}`,
  });

  return (
    <div className='main'>
      <div className="sticky">
        <button onClick={handlePrint} className="btn btn-success  w-25 d-flex align-items-center justify-content-center">Print</button>
      </div>
      <PrescriptionHeaderPrint ref={componentRef} data={data} />
    </div>
  );
};

export default PrescriptionHeader;

