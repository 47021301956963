import { Link, useNavigate } from "react-router-dom";
import { SideMenu } from "../../utils/configs";
import { useEffect, useState } from "react";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../../store/slices/userSlice";
import { Spinner } from "react-bootstrap";
import { image_user } from "../../utils/images";
import { getUserByDesignation } from "../../store/slices/doctorSlice";
import { fetchBeds } from "../../store/slices/bedSlice";
import cookie from 'react-cookies';

const AuthToken = cookie.load('_auth');

const SideNav = ({ isMenuVisible, toggleMenu }) => {
  const _Auth_id = localStorage.getItem("_Auth_id");
  const signOut = useSignOut();
  const navigate = useNavigate();

  useEffect(() => {
    if (!_Auth_id) {
      signOut();
      navigate('/auth/login');
      localStorage.removeItem("_Auth_id");
    }
  }, [_Auth_id, signOut, navigate]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (AuthToken) {
      dispatch(getUserById(_Auth_id));
      dispatch(getUserByDesignation({ designation: "doctor" }));
      dispatch(fetchBeds({ isOccupied: false }));
    }
  }, [_Auth_id, AuthToken, dispatch]);

  const userInfo = useSelector((state) => state.user.userDetails);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({
    name: '',
    role: '',
    designation: '',
    image: '',
  });

  useEffect(() => {
    if (userInfo) {
      setProfileData({
        name: userInfo?.name || 'Unknown User',
        role: userInfo?.role || 'Unknown Role',
        designation: userInfo?.designation || 'Unknown Designation',
        image: userInfo?.image || image_user,
      });
      setLoading(false);
    }
  }, [userInfo]);

  const imageSrc = profileData.image;

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <div className={`offcanvas offcanvas-start ${isMenuVisible ? 'show' : ''}`} id="affanOffcanvas" data-bs-scroll="true" tabIndex="-1"
        aria-labelledby="affanOffcanvsLabel">

        <button className="btn-close btn-close-white text-reset" type="button" onClick={toggleMenu} data-bs-dismiss="offcanvas"
          aria-label="Close"></button>

        <div className="offcanvas-body p-0">
          <div className="sidenav-wrapper">
            <div className="sidenav-profile bg-gradient">
              <div className="sidenav-style1"></div>

              <div className="user-profile">
                <img src={imageSrc} alt="User Profile" />
              </div>
              <div className="user-info">
                <h6 className="user-name mb-0">{profileData.name}</h6>
                <span>{profileData.role || "Unknown Role"}</span>
              </div>
            </div>
            <ul className="sidenav-nav ps-0">
              {
                SideMenu.map((item) => (
                  <li key={item.id || item.title}>
                    <Link to={item.url}>
                      <i className={item.icon}></i> {item.title}
                    </Link>
                  </li>
                ))
              }
            </ul>
            <div className="social-info-wrap">
              <Link to="#">
                <i className="bi bi-facebook"></i>
              </Link>
              <Link to="#">
                <i className="bi bi-twitter"></i>
              </Link>
              <Link to="#">
                <i className="bi bi-linkedin"></i>
              </Link>
            </div>
            <div className="copyright-info">
              <p>
                <span id="copyrightYear">{ }</span>
                &copy; Powered By: <Link to="https://www.inshatech.com" target="_blank">Insha Technologies</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default SideNav;
