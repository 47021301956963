import { Spinner, Accordion } from "react-bootstrap";
import Button from "../../components/common/Button";
import { Doctor } from "./Doctor";
import { default_doctor } from "../../utils/constants";
import { useFormik } from "formik";
import { showAlertMessage } from "../../utils/sweetAlert";
import { useEffect } from "react";
import { format } from "date-fns";
import { useDispatch } from "react-redux";

export const PatientFilter = ({ fetchQuery, searchTerm, setSearchTerm }) => {
    const dispatch = useDispatch();

    // Fetch records
    const handleDoctorChange = (selectedOption) => {
        formik.setFieldValue('doctor', selectedOption ? selectedOption.value : '');
        formik.setFieldTouched('doctor', true);
        const date = new Date();
        const query = {
            doctor: selectedOption.value,
            singleDate: format(date, 'yyyy-MM-dd')
        };
        dispatch(fetchQuery(query));
    };
    // Formik setup
    const formik = useFormik({
        initialValues: {
            doctor: '',
            startDate: format(new Date(), 'yyyy-MM-dd'),
            endDate: format(new Date(), 'yyyy-MM-dd')
        },
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            try {
                dispatch(fetchQuery(values));
            } catch (error) {
                showAlertMessage({
                    icon: 'error',
                    title: 'Error occurred!',
                    text: error.message,
                });
            } finally {
                actions.setSubmitting(false);
            }
        },
    });

    //Load by default Doctor
    useEffect(() => {
        if (default_doctor) {
            handleDoctorChange(default_doctor);
            formik.setFieldValue('doctor', default_doctor);
        }
    }, [default_doctor])

    return (
        <>
            <div className="container">
                <div className="element-heading">
                    <h6>Select Doctor</h6>
                </div>
            </div>
            <div className="container mb-3">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <Doctor formik={formik} autoFocus={true} onChange={handleDoctorChange} />
                            </div>
                            <hr />
                            <Accordion>
                                <Accordion.Item eventKey="0" style={{ border: "3px solid #CFE2FF" }}>
                                    <Accordion.Header>Search Criteria</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row g-lg-2 mb-0">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="date"
                                                        id="startDate"
                                                        name="startDate"
                                                        className="form-control"
                                                        value={formik.values.startDate}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="date"
                                                        id="endDate"
                                                        name="endDate"
                                                        className="form-control"
                                                        value={formik.values.endDate}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-lg-2 mb-0">
                                            <div className="form-group">
                                                <Button
                                                    type="submit"
                                                    className="btn btn-success w-100"
                                                    icon={formik.isSubmitting && <Spinner animation="border" size="sm" />}
                                                    disabled={formik.isSubmitting}
                                                    text={formik.isSubmitting ? "Processing..." : "Search"}
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row g-lg-2 mb-0">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id="search"
                                                    name="search"
                                                    placeholder="Search by name, mobile, or UHID..."
                                                    className="form-control"
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
