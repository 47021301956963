import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import cookie from 'react-cookies'
import { baseUrl } from '../../utils/configs';

const AuthToken = await cookie.load('_auth')

// Async thunk to handle get fitnessSearch information
export const fetchFitnessSearch = createAsyncThunk(
    'auth/fetchFitnessSearch',
    async (query, thunkAPI) => {
        try {
            const response = await axios.get(`${baseUrl}fitness?search=${query}`, {
                headers: {
                    Authorization: AuthToken,
                }
            });
            return response.data.success ? response.data : { success: true, data: [{ name: query }] };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
        }
    }
);


const fitnessSearchSlice = createSlice({
    name: 'fitnessSearch',
    initialState: {
        isLoading: false,
        isError: false,
        fitnessOptions: []
    },
    reducers: {
        fitnessOptions(state, action) {
            state.fitnessOptions = action.payload;
            state._id = action.payload._id;
        }
    },
    extraReducers: (builder) => {
        builder
            // Get fitnessSearch by id
            .addCase(fetchFitnessSearch.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(fetchFitnessSearch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.fitnessOptions = action.payload.data;
                state.isError = false;
            })
            .addCase(fetchFitnessSearch.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
    },
});

export const { fitnessOptions } = fitnessSearchSlice.actions;

export const fitnessSearchReducer = fitnessSearchSlice.reducer;