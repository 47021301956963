import { Link } from "react-router-dom";

const AuthBack = () => {
  return (
    <>
      <div className="login-back-button">
        <Link to="/auth/login">
          <i className="bi bi-arrow-left-short"></i>
        </Link>
      </div>
    </>
  )
};

export default AuthBack
