import React, { useRef } from 'react';
import '../../../assets/css/custom/OPDReceipt.css'
import { format } from 'date-fns';
import rupeesInWord from 'rupeesinword';
import { Link, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
const ReceiptPrint = React.forwardRef(({ data }, ref) => {
  return (
    <>
      <div ref={ref}>
        <table className="table table-striped table-responsive table-bordered table-sm">
          <tbody>
            {/* <tr>
            <td colSpan="4" className="text-center">We Care Almighty Cures</td>
          </tr> */}
            <tr>
              <td colSpan="1" className="text-center heading">
                <div className="hospital-name" >{data?.srNo}</div>
              </td>
              <td colSpan="3" className="text-center heading">
                <div className="hospital-name">Bill Cum Receipt</div>
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="heading">Patient's Name: <b>{`${data?.patient?.name?.toUpperCase()} (${data?.patient?.age}/${({ male: 'M', female: 'F' }[data?.patient?.sex] || 'O')})`}</b></td>
              <td colSpan="2" className="heading">Date: <b>{data?.createdAt && format(data?.createdAt, 'dd-MM-yyyy hh:mm a')}</b></td>
            </tr>
            <tr>
              <td colSpan="1" className="heading">Mobile No: <b>{data?.patient?.mobile}</b></td>
              <td colSpan="1" >Address: {data?.patient?.city}</td>
              <td colSpan="2" >Bill No: {`${data?.date && format(data?.createdAt, 'yyMMdd')}-${data?.srNo}`}</td>
            </tr>
            <tr>
              <td colSpan="2" >UHID: <b>{data?.patient?.uhid}</b></td>
              <td colSpan="2">DSR-No: {data?.srNo}</td>
            </tr>
            <tr>
              <td>Sr. No.</td>
              <td>Services Particulars</td>
              <td>Total</td>
            </tr>
            {
              Object.entries(data?.services || {}).map(([service, fee], i) => (
                <tr key={i}>
                  <td>{i + 1}</td> {/* Increment index to start Sr. No. from 1 */}
                  <td>{service?.toUpperCase()}</td>
                  <td>₹ {fee}/-</td> {/* Assuming total is the same as the fee here */}
                </tr>
              ))
            }
            <tr>
              <td colSpan={2}><b>{data?.total_amount && rupeesInWord(data?.total_amount).toUpperCase()}</b></td>
              <td colSpan={2}>Billed Amount: <b>₹ {data?.total_amount}/-</b></td>
            </tr>
            <tr>
              <td>Payment Method: <b>{data?.transaction_type && data?.transaction_type.toUpperCase()}</b></td>
              <td>Payment Status: <b>{data?.transaction_type && data?.payment_status.toUpperCase()}</b></td>
              <td colSpan={2}>Received Amount: <b>₹ {data?.total_amount}/-</b></td>
            </tr>
            <tr>
              <td colSpan={4}>
                <div>
                  Doctor: <strong>{`Dr. ${data?.doctor?.name && data?.doctor?.name.toUpperCase()}`}</strong></div><div className="heading sign">SIGNATURE: {data?.staff?.name && data?.staff?.name.toUpperCase()}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <div className="powered">
                  Proudly Powered By: <Link to="https://www.inshatech.com" className="link-dark" target="_blank">Insha Technologies</Link>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>
    </>
  )
});

const Receipt = () => {
  const componentRef = useRef();
  const location = useLocation();
  const data = location.state?.data;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Receipt - ${data?.patient?.uhid} - ${data?.createdAt && format(new Date(data?.createdAt), 'dd-MM-yyyy hh:mm a')}`,
  });

  return (
    <div className='main'>
      <div className="sticky">
        <button onClick={handlePrint} className="btn btn-success  w-25 d-flex align-items-center justify-content-center">Print</button>
      </div>
      <ReceiptPrint ref={componentRef} data={data} />
    </div>
  );
}

export default Receipt
