
import AuthBack from "../../components/auth/AuthBack";
import AuthImage from "../../components/auth/AuthImage";
import { image_37 } from "../../utils/images";

const ForgotPassword = () => {
  return (
    <>
      <AuthBack/>
      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          <AuthImage image={image_37} />
          <div className="register-form mt-4">
            <form action="#">
              <div className="form-group text-start mb-3">
                <input className="form-control" type="text" placeholder="Enter your mobile number" />
              </div>
              <button className="btn btn-primary w-100" type="submit">Reset Password</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
};

export default ForgotPassword
