import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Accordion, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { transaction_type, sex, default_doctor } from '../../utils/constants';
import { addOpd, clearOpds, fetchOpds, updateOpd } from '../../store/slices/opdSlice';
import { showAlertMessage } from '../../utils/sweetAlert';
import Button from '../../components/common/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Doctor } from '../../components/common/Doctor';
import { OPDPatients } from '../../components/common/OPDPatients';
import PatientConditions from '../../components/OPD/PatientConditions';
import ServiceTable from '../../components/OPD/ServiceTable';
import PatientVitals from '../../components/OPD/PatientVitals';

const AddOPD = () => {
  const dispatch = useDispatch();
  const staff_id = localStorage.getItem('_Auth_id');
  const [OPD, setOPD] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Fetch the OPD data when needed 
  const { opds } = useSelector((state) => state.opd);
  const { id } = useParams();

  useEffect(() => {
    if (pathname !== '/opd/add') {
      const query = { _id: id };
      dispatch(fetchOpds(query));
    }
  }, [id, dispatch, pathname]);

  useEffect(() => {
    if (opds.length > 0) {
      if (pathname !== '/opd/add') {
        setOPD(opds[0]);
      }
    }
  }, [opds, pathname]);

  const handleReceiptPrint = (data) => {
    navigate('/opd/print/receipt', { state: { data } })
  };

  // Formik setup 
  const formik = useFormik({
    initialValues: {
      doctor: OPD?.doctor?._id || default_doctor,
      name: OPD?.patient?.name || '',
      patient: OPD?.patient?._id || '',
      age: OPD?.patient?.age || '',
      city: OPD?.patient?.city || '',
      sex: OPD?.patient?.sex || '',
      mobile: OPD?.patient?.mobile || '',
      services: OPD?.services || {},
      total_amount: OPD?.amount || 0,
      transaction_type: OPD?.transaction_type || 'cash',
      vitals: OPD?.vitals || {},
      complaints: OPD?.complaints || '',
      diagnosis: OPD?.diagnosis || '',
      examination: OPD?.examination || '',
      history: OPD?.history || '',
      staff: staff_id,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      doctor: Yup.string().required('Doctor selection is required'),
      name: Yup.string().required('Patient name is required'),
      age: Yup.string().required('Age is required'),
      city: Yup.string().required('Address is required'),
      sex: Yup.string().required('Gender is required'),
      total_amount: Yup.string().required('Total Amount is required'),
      transaction_type: Yup.string().required('Payment method is required'),
    }),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      try {
        let response;
        if (pathname !== '/opd/add') {
          response = await dispatch(updateOpd({ id: OPD?._id, updatedOpd: values }));
          if (response?.payload?.success) {
            await showAlertMessage({ text: response.payload.message });
            dispatch(clearOpds())
            actions.resetForm(); // Resets the entire form
            formik.setFieldValue('services', {});
            navigate('/opd/add');
          } else {
            throw new Error(response.payload.message);
          }
        } else {
          response = await dispatch(addOpd(values));
          if (response?.payload?.success) {
            await showAlertMessage({ text: response.payload.message });
            handleReceiptPrint(response.payload.data);
            dispatch(clearOpds())
            actions.resetForm(); // Resets the entire form
            formik.setFieldValue('services', {});
          } else {
            throw new Error(response.payload.message);
          }
        }
      } catch (error) {
        showAlertMessage({
          icon: 'error',
          title: 'Error occurred!',
          text: error.message,
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
  });
  

  // Calculate the total amount
  const calculateTotalAmount = useCallback(() => {
    return Object.values(formik.values.services).reduce((total, amount) => {
      return total + (Number(amount) || 0); // Sum up the amounts, handling any invalid values
    }, 0);
  }, [formik.values.services]);

  // Recalculate total amount whenever services change
  useEffect(() => {
    formik.setFieldValue('total_amount', calculateTotalAmount());
  }, [formik.values.services, calculateTotalAmount]);


  return (
    <>
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="element-heading">
            <h6>Select Doctor</h6>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="container mb-3">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label className="form-label" htmlFor="doctor">Doctor</label>
                  <Doctor formik={formik} autoFocus={false} />
                </div>
                {formik.touched.doctor && formik.errors.doctor ? (
                  <div className="text-danger mb-1">{formik.errors.doctor}</div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Accordion for General Information */}
          <div className="container">
            <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen className="mb-3">
              <Accordion.Item eventKey="0" style={{ border: "3px solid #CFE2FF" }}>
                <Accordion.Header>General Information</Accordion.Header>
                <Accordion.Body>
                  {/* Existing form fields */}
                  <div className="row g-lg-2 mb-0">
                    <div className="form-group mb-3 card">
                      <label className="form-label">Patient</label>
                      <OPDPatients formik={formik} autoFocus={true} />
                    </div>
                    {formik.touched.patient && formik.errors.patient ? (
                      <div className="text-danger mb-1">{formik.errors.patient}</div>
                    ) : null}
                  </div>
                  {/* sr_no & age Input */}
                  <div className="row g-lg-2 mb-0">
                    <div className="col">
                      <div className="form-group mb-3">
                        <label className="form-label">Mobile</label>
                        <input
                          name="mobile"
                          id="mobile"
                          className="form-control"
                          type="text"
                          placeholder="Mobile No."
                          onChange={formik.handleChange}
                          value={formik.values.mobile}
                        />
                      </div>
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <div className="text-danger mb-1">{formik.errors.mobile}</div>
                      ) : null}
                    </div>
                    <div className="col">
                      <div className="form-group mb-3">
                        <label className="form-label">Age</label>
                        <input
                          name="age"
                          id="age"
                          className="form-control"
                          type="number"
                          placeholder="Age"
                          onChange={formik.handleChange}
                          value={formik.values.age}
                        />
                      </div>
                      {formik.touched.age && formik.errors.age ? (
                        <div className="text-danger mb-1">{formik.errors.age}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* mobile & gender Input */}
                  <div className="row g-lg-2 mb-0">
                    <div className="col">
                      <div className="form-group mb-3">
                        <label className="form-label">Sex</label>
                        <Select
                          name="sex"
                          placeholder="Gender"
                          options={sex}
                          value={sex.find(option => option.value === formik.values.sex)}
                          onChange={(selectedOption) => {
                            formik.setFieldValue('sex', selectedOption ? selectedOption.value : '');
                            formik.setFieldTouched('sex', true);
                          }}
                        />
                      </div>
                      {formik.touched.sex && formik.errors.sex ? (
                        <div className="text-danger mb-1">{formik.errors.sex}</div>
                      ) : null}
                    </div>
                    <div className="col">
                      <div className="form-group mb-3">
                        <label className="form-label">City</label>
                        <input
                          name="city"
                          id="city"
                          className="form-control"
                          type="text"
                          placeholder="Address"
                          onChange={formik.handleChange}
                          value={formik.values.city}
                        />
                      </div>
                      {formik.touched.city && formik.errors.city ? (
                        <div className="text-danger mb-1">{formik.errors.city}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* Services Table */}
                  <ServiceTable formik={formik} />
                  {/* mobile & gender Input */}
                  <div className="row g-lg-2 mb-0">
                    <div className="col">
                      <div className="form-group mb-3">
                        <label className="form-label">Final Amount</label>
                        <input
                          name="total_amount"
                          id="total_amount"
                          className="form-control"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values.total_amount}
                          readOnly
                        />
                      </div>
                      {formik.touched.total_amount && formik.errors.total_amount ? (
                        <div className="text-danger mb-1">{formik.errors.total_amount}</div>
                      ) : null}
                    </div>
                    <div className="col">
                      <div className="form-group mb-3">
                        <label className="form-label">Payment Method</label>
                        <Select
                          name="transaction_type"
                          placeholder="Payment Method"
                          options={transaction_type}
                          defaultValue={transaction_type.find(option => option.default)}
                          value={transaction_type.find(option => option.value === formik.values.transaction_type)}
                          onChange={(selectedOption) => {
                            formik.setFieldValue('transaction_type', selectedOption ? selectedOption.value : '');
                            formik.setFieldTouched('transaction_type', true);
                          }}
                        />
                      </div>
                      {formik.touched.transaction_type && formik.errors.transaction_type ? (
                        <div className="text-danger mb-1">{formik.errors.transaction_type}</div>
                      ) : null}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" style={{ border: "3px solid #CFE2FF" }}>
                <Accordion.Header>Vitals</Accordion.Header>
                <Accordion.Body>
                  {/* Patient Vitals */}
                  <PatientVitals formik={formik} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" style={{ border: "3px solid #CFE2FF" }}>
                <Accordion.Header>Condition</Accordion.Header>
                <Accordion.Body>
                  {/* Patient Conditions */}
                  <PatientConditions formik={formik} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="row g-lg-2 mb-0">
              <div className="form-group mb-3">
                <Button type="submit" className="btn btn-success w-100" icon={formik.isSubmitting && <Spinner animation="border" size="sm" />} disabled={formik.isSubmitting} text={formik.isSubmitting ? "Processing..." : "Save Changes"} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddOPD;
