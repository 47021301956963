import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../utils/configs';

// Base URL for API
const API_URL = `${baseUrl}auth/login`;

// Async thunk to handle login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(API_URL, data);
      // Assuming the API sends back a token
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    isError: false,
    authDetails: null
  },
  reducers: {
    authDetails(state, action) {
      state.authDetails = action.payload.data;
      state._id = action.payload._id;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.authDetails = action.payload;
        state.isError = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { authDetails } = authSlice.actions;

export const authReducer = authSlice.reducer;
