import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import FooterNav from "./components/common/FooterNav";
import Header from "./components/common/Header";
import Internet from "./components/common/Internet";
import SideNav from "./components/common/SideNav";
import AuthOutlet from "@auth-kit/react-router/AuthOutlet";

// Page Components
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ConfirmOTP from "./pages/auth/ConfirmOTP";
import Logout from "./pages/auth/Logout";
import Settings from "./pages/settings/Settings";
import Profile from "./pages/settings/Profile";
import Doctors from "./pages/settings/Doctors";
import AddOPD from "./pages/OPD/AddOPD";
import OPDList from "./pages/OPD/OPDList";
import Receipt from "./components/print/opd/Receipt";
import AddIPD from "./pages/IPD/AddIPD";
import IPDList from "./pages/IPD/IPDList";
import AddFitness from "./pages/fitness/AddFitness";
import FitnessList from "./pages/fitness/FitnessList";
import AddDischargeCert from "./pages/discharge/AddDischargeCert";
import DischargeCertList from "./pages/discharge/DischargeCertList";
import Scalp from "./pages/Scalp";
import PrescriptionHeader from "./components/print/opd/PrescriptionHeader";

function App() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [pageTitle, setPageTitle] = useState('Home');
  const location = useLocation();

  const hideLayoutRoutes = ['/auth/login', '/auth/forgot-password', '/auth/otp-confirm'];
  const isAuthRoute = hideLayoutRoutes.includes(location.pathname);

  // Toggles side navigation visibility
  const toggleMenu = () => setIsMenuVisible(prev => !prev);

  // Update title based on path and reset side menu visibility on path change
  useEffect(() => {
    if (isMenuVisible && !isAuthRoute) {
      setIsMenuVisible(false);  // Ensures this only runs when the conditions are met
    }
  }, [isMenuVisible, isAuthRoute]); // 1. Only dependencies for menu visibility

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter(Boolean);

    // Use only the first two parts (if they exist) for the title
    const titleParts = [pathParts[0], pathParts[1]].filter(Boolean);
    setPageTitle(titleParts.map(part => part.toUpperCase()).join(' ') || 'HOME');
  }, [location.pathname]);



  // Handle theme and view mode from localStorage
  useEffect(() => {
    const theme = localStorage.getItem('theme');
    const viewMode = localStorage.getItem('view-mode');
    if (theme) document.documentElement.setAttribute('data-theme', theme);
    if (viewMode) document.documentElement.setAttribute('view-mode', viewMode);
  }, []);

  return (
    <>
      <Internet />
      {!isAuthRoute && <Header toggleMenu={toggleMenu} pageTitle={pageTitle} />}
      {!isAuthRoute && <SideNav isMenuVisible={isMenuVisible} toggleMenu={toggleMenu} />}
      <Routes>
        <Route element={<AuthOutlet fallbackPath='/auth/login' />}>
          <Route path="/" element={<Home />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/profile" element={<Profile />} />
          <Route path="/auth/logout" element={<Logout />} />
          <Route path="/doctors" element={<Doctors />} />

          {/* OPD Routes */}
          <Route path="/opd/add" element={<AddOPD />} />
          <Route path="/opd/list" element={<OPDList />} />
          <Route path="/opd/edit/:id" element={<AddOPD />} />
          {/* <Route path="/opd/print/receipt/:id" element={<Receipt />} /> */}
          <Route path="/opd/print/prescription" element={<PrescriptionHeader />} />
          <Route path="/opd/print/receipt" element={<Receipt />} />


          {/* IPD Routes */}
          <Route path="/ipd/add" element={<AddIPD />} />
          <Route path="/ipd/list" element={<IPDList />} />

          {/* Fitness Routes */}
          <Route path="/fitness/add" element={<AddFitness />} />
          <Route path="/fitness/list" element={<FitnessList />} />
          <Route path="/fitness/edit/:id" element={<AddFitness />} />

          {/* Discharge Certificate Routes */}
          <Route path="/discharge-certificate/add" element={<AddDischargeCert />} />
          <Route path="/discharge-certificate/list" element={<DischargeCertList />} />
          <Route path="/discharge-certificate/edit/:id" element={<AddDischargeCert />} />

          {/* Scalp Route */}
          <Route path="/scalp/manage" element={<Scalp />} />
        </Route>

        {/* Authentication Routes */}
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/otp-confirm" element={<ConfirmOTP />} />
      </Routes>
      {!isAuthRoute && <FooterNav />}
    </>
  );
}

export default App;
