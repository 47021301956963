export const sex = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
]

export const transaction_type = [
  { label: "Cash", value: "cash", default: true },
  { label: "Online", value: "online" },
]

export const serviceOptions = [
  { label: "Select a service", value: "", amount: 0 },
  { value: 'opd', label: 'OPD', amount: 200 },
  { value: 'follow_up', label: 'FOLLOW UP', amount: 100 },
  { value: 'ecg', label: 'ECG', amount: 300 },
  { value: 'sugar', label: 'Sugar', amount: 50 },
  { value: 'fitness_rahat', label: 'FITNESS RAHAT', amount: 500 },
  { value: 'tft', label: 'TFT', amount: 100 },
  { value: 'opd_500', label: 'OPD (500)', amount: 500 },
  { value: 'other', label: 'OTHER', amount: 0 },
  { value: 'discount', label: 'DISCOUNT', amount: 0 },
  { value: 'collection', label: 'COLLECTION', amount: 0 },
  // Add more service options as needed
];


export const vitals = {
  Blood_Pressure: "120/80",
  Plus_Rate: "",
  Spo2: "",
  Weight: "",
  Sugar: "",
};

export const conditions = {
  Complaints: "",
  Diagnosis: "",
  Examination: "",
  History: "",
}

export const default_doctor = localStorage.getItem("default_doctor");
