import { AutoComplete } from 'primereact/autocomplete';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPatients } from '../../store/slices/patientSlice';
import { useEffect, useState } from 'react';

export const OPDPatients = ({ formik, autoFocus }) => {
    const { options, isLoading } = useSelector((state) => state.patient);
    const dispatch = useDispatch();

    const handleSearch = (query) => {
        dispatch(fetchPatients(query));
    };

    const handleSelect = (e) => {
        const selected = e.value;
        if (selected) {
            formik.setFieldValue('name', selected.name);
            formik.setFieldValue('patient', selected._id);
            formik.setFieldValue('age', selected.age || '');
            formik.setFieldValue('city', selected.city || '');
            formik.setFieldValue('sex', selected.sex || '');
            formik.setFieldValue('mobile', selected.mobile || '');
        }
    };
    const [filteredOptions, setFilteredOptions] = useState([])

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);
    return (
        <>
            <AutoComplete
                value={formik.values.name || ''}
                suggestions={filteredOptions}
                completeMethod={(e) => handleSearch(e.query)}
                field="name"
                placeholder="Patient..."
                loading={isLoading.toString()}
                inputClassName="form-control"
                onChange={(e) => formik.setFieldValue('name', e.value)}
                onSelect={handleSelect}
                autoFocus={autoFocus}
                itemTemplate={(option) => (
                    <div>
                        <span>{option.name}</span>
                        {option.uhid && (
                            <div>
                                <small> - {option.uhid}</small>
                                <p className="mb-0">
                                    {`${option.mobile} | ${option.age}/${option.sex === 'male' ? 'M' : option.sex === 'female' ? 'F' : 'O'} | ${option.city}`}
                                </p>
                                <hr className="mt-1 mb-0" />
                            </div>
                        )}
                    </div>
                )}
            />
        </>
    )
}
