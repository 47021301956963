import React from 'react'
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { showAlertMessage } from '../../utils/sweetAlert';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { clearScalps, updateScalp } from '../../store/slices/scalpSlice';

const Discharge = ({ showDischargeModal, setShowDischargeModal, data }) => {
    const staff_id = localStorage.getItem('_Auth_id');

    const dispatch = useDispatch();

    // Formik setup
    const formik = useFormik({
        initialValues: {
            name: `${data?.patient?.name} - ${data?.patient?.uhid}` || '',
            dod: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
            staff: staff_id,
            _id: data?._id,
            is_admitted: false,
            fees: ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Patient is required'),
            dod: Yup.string().required('Date time of discharge is required'),
            fees: Yup.string()
                .matches(/^[₹]?[0-9]{1,3}(?:,?[0-9]{2})*(?:\.[0-9]{1,2})?$/, 'Please enter a valid amount in Indian Rupees')
                .required('Fees is required'),

        }),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);
            try {
                const query = {
                    id: values._id,
                    updatedScalp: values,
                }
                const response = await dispatch(updateScalp(query));
                if (response?.payload?.success) {
                    await showAlertMessage({ text: response?.payload?.message });
                    actions.resetForm();
                    dispatch(clearScalps());
                    setShowDischargeModal(false);
                } else {
                    throw new Error(response?.payload?.message);
                }
            } catch (error) {
                showAlertMessage({
                    icon: 'error',
                    title: 'Error occurred!',
                    text: error.message,
                });
            } finally {
                actions.setSubmitting(false);
            }
        },
    });

    return (
        <>
            <Modal show={showDischargeModal} onHide={() => setShowDischargeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="element-heading">
                            <h6>Discharge {data?.patient?.name} - {data?.patient?.uhid} ({data?.srNo}) </h6>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="row mb-3">
                            <div className="form-group mb-3">

                                <input
                                    name="name"
                                    id='name'
                                    className="form-control"
                                    type="text"
                                    placeholder="Name"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    readOnly // Making this field read-only as it's pre-filled
                                />
                            </div>
                            {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger mb-1">{formik.errors.name}</div>
                            ) : null}
                        </div>
                        <div className="row mb-3">
                            <div className="form-group mb-3">
                                <input
                                    name="dod"
                                    id='dod'
                                    className="form-control"
                                    type="datetime-local"
                                    onChange={formik.handleChange}
                                    value={formik.values.dod}
                                    autoFocus
                                />
                            </div>
                            {formik.touched.dod && formik.errors.dod ? (
                                <div className="text-danger mb-1">{formik.errors.dod}</div>
                            ) : null}
                        </div>
                        <div className="row mb-3">
                            <div className="form-group mb-3">
                                <input
                                    name="fees"
                                    id='fees'
                                    className="form-control"
                                    type="text"
                                    placeholder="Fees"
                                    onChange={formik.handleChange}
                                    value={formik.values.fees}
                                />
                            </div>
                            {formik.touched.fees && formik.errors.fees ? (
                                <div className="text-danger mb-1">{formik.errors.fees}</div>
                            ) : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className="btn m-1 btn-sm btn-danger" onClick={() => setShowDischargeModal(false)}>
                            Close
                        </button>
                        <button type="submit" className="btn m-1 btn-sm btn-success" disabled={formik.isSubmitting}>
                            {formik.isSubmitting ? <Spinner animation="border" size="sm" /> : 'Save Changes'}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default Discharge;
