import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { showAlertMessage } from "../../utils/sweetAlert";

const Settings = () => {

  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem('theme') === 'dark';
  });

  const [rtlMode, setRtlMode] = useState(() => {
    return localStorage.getItem('view-mode') === 'rtl';
  });

  useEffect(() => {
    const theme = darkMode ? 'dark' : 'light';
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [darkMode]);

  useEffect(() => {
    const viewMode = rtlMode ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('view-mode', viewMode);
    localStorage.setItem('view-mode', viewMode);
  }, [rtlMode]);

  // Doctor selection setup 
  const doctorInfo = useSelector((state) => state.doctor.doctorDetails);
  const [doctor, setDoctor] = useState([]);

  useEffect(() => {
    if (doctorInfo) {
      setDoctor([{ value: '', label: 'Select a doctor' }, ...doctorInfo.map((item) => ({ value: item._id, label: item.name }))]);
    }
  }, [doctorInfo]);

  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const handleSetDefaultDoctor = async() => {
    if (selectedDoctor) {
      localStorage.setItem("default_doctor", selectedDoctor);
      await showAlertMessage({ text: 'Default doctor set successfully!' });
    } else {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: 'Please select a doctor first.',
      });
    }
  };

  const handleRemoveDefaultDoctor = async() => {
    localStorage.removeItem("default_doctor");
    setSelectedDoctor(null);
    await showAlertMessage({ text: 'Default doctor removed successfully!' });
  };

  return (
    <>
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="card mb-3 shadow-sm">
            <div className="card-body direction-rtl">
              <p className="mb-2">Settings</p>

              <div className="single-setting-panel">
                <div className="form-check form-switch mb-2">
                  <input className="form-check-input" type="checkbox" id="darkSwitch" checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
                  <label className="form-check-label" htmlFor="darkSwitch">Dark Mode</label>
                </div>
              </div>

              <div className="single-setting-panel">
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" id="rtlSwitch" checked={rtlMode} onChange={() => setRtlMode(!rtlMode)} />
                  <label className="form-check-label" htmlFor="rtlSwitch">RTL Mode</label>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3 shadow-sm">
            <div className="card-body direction-rtl">
              <p className="mb-2">Account Setup</p>

              <div className="single-setting-panel">
                <Link to="/settings/profile">
                  <div className="icon-wrapper">
                    <i className="bi bi-person"></i>
                  </div>
                  Update Profile
                </Link>
              </div>

              <div className="single-setting-panel">
                <Link to="privacy-policy">
                  <div className="icon-wrapper bg-danger">
                    <i className="bi bi-shield-lock"></i>
                  </div>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>

          <div className="card shadow-sm mb-3">
            <div className="card-body direction-rtl">
              <p className="mb-2">Accounts</p>

              <div className="single-setting-panel">
                <Link to="/auth/logout">
                  <div className="icon-wrapper bg-danger">
                    <i className="bi bi-box-arrow-right"></i>
                  </div>
                  Logout
                </Link>
              </div>
            </div>
          </div>
          <div className="card shadow-sm">
            <div className="card-body direction-rtl">
              <p className="mb-2">Select Doctor</p>

              <div className="single-setting-panel">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <Select
                        name="doctor"
                        placeholder="Doctor"
                        options={doctor}
                        onChange={(selectedOption) => setSelectedDoctor(selectedOption.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      onClick={handleSetDefaultDoctor}
                    >
                      Set Doctor
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleRemoveDefaultDoctor}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Settings
