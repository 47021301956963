import { format } from 'date-fns';
import React from 'react'
import '../../../assets/css/custom/FitnessCert.css'
import { Link } from 'react-router-dom';

const DischargeCertificate = React.forwardRef(({ data }, ref) => {

    const formattedConditionOD = data?.conditionOD && Object.entries(data?.conditionOD)
        .map(([key, value]) => `${key} = ${value}`)
        .join(', ');

    return (
        <>
            <main ref={ref}>
                <table className="table table-striped table-responsive table-bordered table-sm">
                    <tbody>
                        <tr>
                            <td colSpan="3" className="text-center heading fw-bold">DISCHARGE CERTIFICATE</td>
                        </tr>
                        <tr>
                            <td className="heading" colSpan="2">Patient's Name: <b>{data?.patient?.name?.toUpperCase()}</b></td>
                            <td>Age/Sex: <b>{`${data?.patient?.age}/${({ male: 'M', female: 'F' }[data?.patient?.sex] || 'O')}`}</b></td>
                        </tr>
                        <tr>
                            <td className="heading">UHID: <b>{data?.patient?.uhid}</b></td>
                            <td className="heading">Address: <b>{data?.patient?.city.toUpperCase()}</b></td>
                            <td>Mobile: <b>{data?.patient?.mobile}</b></td>
                        </tr>
                        <tr>
                            <td>DOA: <b>{data?.doa && format(data?.doa, 'dd-MM-yyyy hh:mm:ss a')}</b></td>
                            <td>DOD: <b>{data?.dod && format(data?.dod, 'dd-MM-yyyy hh:mm:ss a')}</b></td>
                            <td>Bed: <b>{data?.bed?.name}</b></td>
                        </tr>
                        <tr>
                            <td className="heading">Type of discharge:</td>
                            <td className="heading fw-bold" colSpan={2}>{data?.tod.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td className="heading">Diagnosis:</td>
                            <td className="heading fw-bold" colSpan={2}>{data?.diagnosis.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td className="heading">Clinical Notes:</td>
                            <td className="heading fw-bold" colSpan={2}>{data?.clinical_notes.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td className="heading">investigation:</td>
                            <td className="heading fw-bold" colSpan={2}>{data?.investigation.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td className="heading">Treatment Given:</td>
                            <td className="heading fw-bold" colSpan={2}>{data?.treatment.toUpperCase()}</td>
                        </tr>
                        <tr>
                            <td className="heading">Condition on Discharge:</td>
                            <td className="heading fw-bold" colSpan={2}>{formattedConditionOD}</td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <div className="heading footer">
                                </div>
                                <div className="heading sign fw-bold">DOCTOR'S SIGNATURE</div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center heading" colSpan={3}>Proudly Powered By: <Link to="https://www.inshatech.com" target='_blank'>Insha Technologies</Link></td>
                        </tr>
                    </tbody>
                </table>
            </main>
        </>
    )
});

export default DischargeCertificate
