import React, { useEffect, useState } from 'react';
import { showAlertMessage } from '../../utils/sweetAlert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { clearIpds, fetchIpds } from '../../store/slices/ipdSlice';
import { format } from 'date-fns';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useLocation, useParams } from 'react-router-dom';
import { fetchDischargeSearch } from '../../store/slices/dischargeSearchSlice';
import Button from '../../components/common/Button';
import { Spinner } from 'react-bootstrap';
import { clearDischargeCert, createDischargeCert, fetchDischargeCert, updateDischargeCert } from '../../store/slices/dischargeCertSlice';

const AddDischargeCert = () => {
    const dispatch = useDispatch();
    const { ipds } = useSelector((state) => state.ipd);
    const { dischargeOptions, isLoading } = useSelector((state) => state.dischargeSearch);
    const staff_id = localStorage.getItem('_Auth_id');
    const { pathname } = useLocation();

    const tods = [
        { value: '', label: 'Select a discharge type' },
        { value: 'Discharge', label: 'Discharge' },
        { value: 'Discharge on request', label: 'Discharge on request' },
        { value: 'AMA Discharge', label: 'AMA Discharge' },
        { value: 'Refer', label: 'Refer' },
    ];
    const [singleDischargeCert, setSingleDischargeCert] = useState(null);

    //Fetch Fitness
    const { dischargeCert = [], loading } = useSelector((state) => state.dischargeCert);
    const { id } = useParams();
    const [updated, setUpdated] = useState(false);
    useEffect(() => {
        if (pathname !== '/discharge-certificate/add') {
            const query = {
                _id: id
            }
            setUpdated(true);
            dispatch(fetchDischargeCert(query));
        }
    }, [pathname, dispatch, id]);

    useEffect(() => {
        if (dischargeCert.length > 0) {
            if (pathname !== '/discharge-certificate/add') {
                setSingleDischargeCert(dischargeCert[0]);
            }
        }
    }, [dischargeCert, pathname]);

    // Search for Diagnosis
    const handleSearchDiagnosis = (query) => {
        dispatch(fetchDischargeSearch(query));
    };

    // search for clinical_notes
    const handleSearchClinicalNotes = (query) => {
        dispatch(fetchDischargeSearch(query));
    };

    // search for treatment
    const handleSearchTreatment = (query) => {
        dispatch(fetchDischargeSearch(query));
    };

    // Select doctor
    const doctorInfo = useSelector((state) => state.doctor.doctorDetails);
    const doctorOptions = [
        { value: '', label: 'Select a doctor' },
        ...(doctorInfo?.map((item) => ({
            value: item._id,
            label: item.name,
        })) || [])
    ];


    // Load Patients
    const [patients, setPatients] = useState([]);
    useEffect(() => {
        if (ipds) {
            const patientList = [
                { value: '', label: 'Select a patient' },
                ...ipds.map((item) => ({
                    value: item.patient._id,
                    label: item.patient.name,
                })),
            ];

            setPatients(patientList);
        }
    }, [ipds]);

    // Handle Doctor selection
    const handleDoctorChange = (selectedOption) => {
        formik.setFieldValue('doctor', selectedOption?.value || '');
        const query = { doctor: selectedOption?.value, is_admitted: true };
        dispatch(fetchIpds(query));
    };

    // Handle Patient selection
    const handlePatientChange = (selectedOption) => {
        formik.setFieldValue('patient', selectedOption?.value || '');

        const selectedIpd = ipds.find((ipd) => ipd.patient._id === selectedOption.value);
        if (selectedIpd) {
            formik.setFieldValue('doa', selectedIpd.doa ? format(new Date(selectedIpd.doa), "yyyy-MM-dd'T'HH:mm") : '');
            formik.setFieldValue('bedName', selectedIpd.bed.name || '');
            formik.setFieldValue('bed', selectedIpd.bed._id || '');
            formik.setFieldValue('ipd_id', selectedIpd._id || '');
        }
    };

    // Formik setup
    const formik = useFormik({
        initialValues: {
            doctor: singleDischargeCert?.doctor?._id || '',
            patient: singleDischargeCert?.patient?._id || '',
            doa: singleDischargeCert?.doa
                ? format(new Date(singleDischargeCert.doa), "yyyy-MM-dd'T'HH:mm")
                : '',
            bed: singleDischargeCert?.bed?._id || '',
            bedName: singleDischargeCert?.bed?.name || '',
            tod: singleDischargeCert?.tod || '',
            date: singleDischargeCert?.date
                ? format(new Date(singleDischargeCert.date), "yyyy-MM-dd")
                : format(new Date(), "yyyy-MM-dd"),
            dod: singleDischargeCert?.dod
                ? format(new Date(singleDischargeCert.dod), "yyyy-MM-dd'T'HH:mm")
                : format(new Date(), "yyyy-MM-dd'T'HH:mm"),
            investigation: singleDischargeCert?.investigation || 'All reports are attached.',
            conditionOD: {
                GC: singleDischargeCert?.conditionOD?.GC || 'BETTER',
                PB: singleDischargeCert?.conditionOD?.PB || '120/90 (mm of Hg)',
                PR: singleDischargeCert?.conditionOD?.PR || '80 (bpm)',
                RS: singleDischargeCert?.conditionOD?.RS || 'B/L CLEAR',
                CVS: singleDischargeCert?.conditionOD?.CVS || 'S1S2 PRESENT',
                CNS: singleDischargeCert?.conditionOD?.CNS || 'CONSCIOUS',
            },
            diagnosis: singleDischargeCert?.diagnosis || '',
            clinical_notes: singleDischargeCert?.clinical_notes || '',
            treatment: singleDischargeCert?.treatment || '',
            staff: singleDischargeCert?.staff || staff_id,
        },
        validationSchema: Yup.object({
            doctor: Yup.string().required('Doctor is required'),
            patient: Yup.string().required('Patient is required'),
            dod: Yup.string().required('Date time of discharge is required'),
            doa: Yup.string().required('Date time of admission is required'),
            conditionOD: Yup.object({
                GC: Yup.string().required('General Condition is required'),
                PB: Yup.string().required('Blood Pressure is required'),
                PR: Yup.string().required('Pulse is required'),
                RS: Yup.string().required('Respiratory Status is required'),
                CVS: Yup.string().required('Cardiac Status is required'),
                CNS: Yup.string().required('CNS Status is required'),
            }),
            diagnosis: Yup.string().required('Diagnosis is required'),
            tod: Yup.string().required('Type of discharge is required'),
            clinical_notes: Yup.string().required('Clinical notes is required'),
            treatment: Yup.string().required('Treatment is required'),

        }),
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            try {
                let response;
                if (pathname !== '/discharge-certificate/add') {
                    response = await dispatch(updateDischargeCert({ id: singleDischargeCert._id, updatedDischargeCert: values }));
                } else {
                    response = await dispatch(createDischargeCert(values))
                }
                if (response?.payload?.success) {
                    await showAlertMessage({ text: response?.payload?.message });
                    actions.resetForm();
                    dispatch(clearDischargeCert());
                    dispatch(clearIpds());
                } else {
                    throw new Error(response?.payload?.message);
                }
            } catch (error) {
                showAlertMessage({
                    icon: 'error',
                    title: 'Error occurred!',
                    text: error.message,
                });
            } finally {
                actions.setSubmitting(false);
            }
        },
    });

    return (
        <div className="page-content-wrapper py-3">
            <div className="container">
                <div className="element-heading">
                    <h6>Patient Information</h6>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="container mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3">
                                <label className="form-label" htmlFor="doctor">Doctor</label>
                                <Select
                                    name="doctor"
                                    placeholder="Doctor"
                                    options={doctorOptions}
                                    value={doctorOptions.find(option => option.value === formik.values.doctor)}
                                    onChange={handleDoctorChange}
                                    disabled={updated}
                                    autoFocus
                                />
                                {formik.touched.doctor && formik.errors.doctor && (
                                    <div className="text-danger mb-1">{formik.errors.doctor}</div>
                                )}
                            </div>
                            <hr />
                            <div className="form-group mb-3">
                                <label className="form-label" htmlFor="patient">Patient</label>
                                <Select
                                    name="patient"
                                    placeholder="Patient"
                                    options={patients}
                                    value={patients.find(option => option.value === formik.values.patient)}
                                    onChange={handlePatientChange}
                                    disabled={updated}
                                />
                                {formik.touched.patient && formik.errors.patient && (
                                    <div className="text-danger mb-1">{formik.errors.patient}</div>
                                )}
                            </div>
                            <div className="row g-lg-2 mb-0">
                                <div className="col">
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="date">Date</label>
                                        <input
                                            name="date"
                                            id="date"
                                            className="form-control"
                                            type="date"
                                            onChange={formik.handleChange}
                                            value={formik.values.date}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="bedName">Bed</label>
                                        <input
                                            name="bedName"
                                            id="bedName"
                                            className="form-control"
                                            type="text"
                                            value={formik.values.bedName}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row g-lg-2 mb-0">
                                <div className="col">
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="doa">Date Time of Admission</label>
                                        <input
                                            name="doa"
                                            id="doa"
                                            className="form-control"
                                            type="datetime-local"
                                            value={formik.values.doa}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="dod">Date Time of Discharge</label>
                                        <input
                                            name="dod"
                                            id="dod"
                                            className="form-control"
                                            type="datetime-local"
                                            onChange={formik.handleChange}
                                            value={formik.values.dod}
                                            readOnly={updated}
                                        />
                                        {formik.touched.dod && formik.errors.dod && (
                                            <div className="text-danger mb-1">{formik.errors.dod}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="form-label" htmlFor="tod">Type of Discharge</label>
                                <Select
                                    name="tod"
                                    placeholder="Type of Discharge"
                                    options={tods}
                                    value={tods.find(option => option.value === formik.values.tod)}
                                    onChange={(option) => formik.setFieldValue('tod', option?.value || '')}
                                />
                                {formik.touched.tod && formik.errors.tod && (
                                    <div className="text-danger mb-1">{formik.errors.tod}</div>
                                )}
                            </div>

                            <div className="row g-lg-2 mb-2">
                                <div className="form-group mb-1">
                                    <label className="form-label" htmlFor="diagnosis">Diagnosis</label>
                                    <AsyncTypeahead
                                        id="diagnosis"
                                        isLoading={isLoading}
                                        labelKey="diagnosis"
                                        minLength={2}
                                        onSearch={handleSearchDiagnosis}
                                        options={dischargeOptions || []}
                                        placeholder="Diagnosis"
                                        allowNew={true}
                                        selected={formik.values.diagnosis ? [{ diagnosis: formik.values.diagnosis }] : []}
                                        renderMenuItemChildren={(option) => (
                                            <span>{option.diagnosis}</span>
                                        )}
                                        onChange={(selected) => {
                                            // If there is no selected option, use the manual input value
                                            const value = selected.length > 0 ? selected[0].diagnosis : formik.values.diagnosis;
                                            formik.setFieldValue('diagnosis', value);
                                        }}
                                    />

                                </div>
                                {formik.touched.diagnosis && formik.errors.diagnosis ? (
                                    <div className="text-danger mb-1">{formik.errors.diagnosis}</div>
                                ) : null}
                            </div>
                            <div className="row g-lg-2 mb-2">
                                <div className="form-group mb-1">
                                    <label className="form-label" htmlFor="clinical_notes">Clinical Notes</label>
                                    <AsyncTypeahead
                                        id="clinical_notes"
                                        isLoading={isLoading}
                                        labelKey="clinical_notes"
                                        minLength={2}
                                        onSearch={handleSearchClinicalNotes}
                                        options={dischargeOptions || []}
                                        placeholder="Clinical Notes"
                                        allowNew={true}
                                        selected={formik.values.clinical_notes ? [{ clinical_notes: formik.values.clinical_notes }] : []}
                                        renderMenuItemChildren={(option) => (
                                            <span>{option.clinical_notes}</span>
                                        )}
                                        onChange={(selected) => {
                                            const value = selected.length > 0 ? selected[0]?.clinical_notes : formik.values.clinical_notes;
                                            formik.setFieldValue('clinical_notes', value);
                                        }}
                                    />
                                </div>
                                {formik.touched.clinical_notes && formik.errors.clinical_notes ? (
                                    <div className="text-danger mb-1">{formik.errors.clinical_notes}</div>
                                ) : null}
                            </div>
                            <div className="row g-lg-2 mb-0">
                                <div className="form-group mb-3">
                                    <label className="form-label" htmlFor="tod">Investigation</label>
                                    <input
                                        name="investigation"
                                        id="investigation"
                                        className="form-control"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.investigation}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row g-lg-2 mb-2">
                                <div className="form-group mb-1">
                                    <label className="form-label" htmlFor="treatment">Treatment Given</label>
                                    <AsyncTypeahead
                                        id="async-treatment-search"
                                        isLoading={isLoading}
                                        labelKey="treatment"
                                        minLength={2}
                                        onSearch={handleSearchTreatment}
                                        options={dischargeOptions || []}
                                        placeholder="Treatment Given"
                                        allowNew={true}
                                        selected={formik.values.treatment ? [{ treatment: formik.values.treatment }] : []}
                                        renderMenuItemChildren={(option) => (
                                            <span>{option.treatment}</span>
                                        )}
                                        onChange={(selected) => {
                                            const value = selected.length > 0 ? selected[0]?.treatment : formik.values.clinical_notes;
                                            formik.setFieldValue('treatment', value);
                                        }}
                                    />
                                </div>
                                {formik.touched.treatment && formik.errors.treatment ? (
                                    <div className="text-danger mb-1">{formik.errors.treatment}</div>
                                ) : null}
                            </div>
                            {Object.entries(formik.values.conditionOD).map(([key, value], index) => (
                                index % 3 === 0 && (
                                    <div className="row g-lg-2 mb-3" key={index}>
                                        {[key, ...Object.keys(formik.values.conditionOD).slice(index + 1, index + 3)].map((field, i) => (
                                            <div className="col" key={i}>
                                                <label className="form-label" htmlFor={`conditionOD.${field}`}>{field}</label>
                                                <input
                                                    type="text"
                                                    id={`conditionOD.${field}`}
                                                    name={`conditionOD.${field}`}
                                                    className="form-control"
                                                    value={formik.values.conditionOD[field]}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )
                            ))}
                            <div className="row g-lg-2 mb-0">
                                <div className="form-group mb-3">
                                    <Button type="submit" className="btn btn-success w-100" icon={formik.isSubmitting && <Spinner animation="border" size="sm" />} disabled={formik.isSubmitting} text={formik.isSubmitting ? "Processing..." : "Discharge & Certificate"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddDischargeCert;
