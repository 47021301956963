import { Link } from "react-router-dom";
import { homeMenu } from "../utils/configs";

const Home = () => {
  return (
    <>
      <div className="page-content-wrapper">
        <div className="pt-3"></div>
        {
          homeMenu.map((item, index) => (
            <div key={index} className="container direction-rtl">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row g-3">
                    {
                      item.menu.map((menu, i) => (
                        <div key={i} className={item.col}>
                          <div className="text-center">
                            <Link to={menu.link}>
                              <div className="card mx-auto bg-gray">
                                <h3 className={menu.iconColor}>{menu.icon}</h3>
                              </div>
                              <p className="mb-0">{menu.title}</p>
                            </Link>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        <div className="pb-3"></div>
      </div>

    </>
  )
};

export default Home
