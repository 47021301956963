import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import cookie from 'react-cookies'
import { baseUrl } from '../../utils/configs';

// Base URL for API
const API_URL = `${baseUrl}user`;

const AuthToken = await cookie.load('_auth')

// Async thunk to handle get doctor information
export const getUserByDesignation = createAsyncThunk(
  'auth/getUserByDesignation',
  async ({designation = 'doctor'}, thunkAPI) => {
    try {
      const response = await axios.get(API_URL, {
        headers: {
          Authorization: AuthToken,
        },
        params: {
          designation, // Include designation as query param
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);


const doctorSlice = createSlice({
  name: 'doctor',
  initialState: {
    isLoading: false,
    isError: false,
    doctorDetails: []
  },
  reducers: {
    doctorDetails(state, action) {
      state.doctorDetails = action.payload;
      state._id = action.payload._id;
    }
  },
  extraReducers: (builder) => {
    builder
    // Get doctor by id
      .addCase(getUserByDesignation.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUserByDesignation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.doctorDetails = action.payload.data;
        state.isError = false;
      })
      .addCase(getUserByDesignation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
  },
});

export const { doctorDetails } = doctorSlice.actions;

export const doctorReducer = doctorSlice.reducer;