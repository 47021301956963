import { ErrorMessage, Field, Form, Formik } from "formik";
import { putUserById } from "../../store/slices/userSlice";
import { showAlertMessage } from "../../utils/sweetAlert";
import { useDispatch } from "react-redux";
import Button from "../common/Button";
import { Spinner } from "react-bootstrap";
import * as Yup from 'yup';
import { useState, useEffect } from "react";

const ProfileForm = ({ userInfo }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    _id: '',
    name: '',
    mobile: '',
    email: '',
    designation: '',
    role: ''
  });

  // Populate initial values from userInfo when available
  useEffect(() => {
    if (userInfo) {
      setInitialValues({
        _id: userInfo._id || '',
        name: userInfo.name || '',
        mobile: userInfo.mobile || '',
        email: userInfo.email || '',
        designation: userInfo.designation || '',
        role: userInfo.role || ''
      });
      setLoading(false); // Stop loading when data is set
    }
  }, [userInfo]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(
        putUserById({ id: initialValues._id, data: values })
      );
      if (response?.payload?.success) {
        await showAlertMessage({ text: response?.payload?.message });
      } else {
        throw new Error(response?.payload?.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  if (loading) {
    return <Spinner animation="border" />;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="name">Name</label>
              <Field
                className="form-control"
                name="name"
                type="text"
                placeholder="Name"
              />
              <ErrorMessage className="text-danger mb-1" name="name" component="div" />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="mobile">Mobile</label>
              <Field
                name="mobile"
                className="form-control"
                type="text"
                placeholder="Mobile"
                readOnly
              />
              <ErrorMessage className="text-danger mb-1" name="mobile" component="div" />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="email">Email Address</label>
              <Field
                className="form-control"
                name="email"
                type="email"
                placeholder="Email Address"
                readOnly
              />
              <ErrorMessage className="text-danger mb-1" name="email" component="div" />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="designation">Designation</label>
              <Field
                className="form-control"
                name="designation"
                type="text"
                placeholder="Designation"
                readOnly
              />
              <ErrorMessage className="text-danger mb-1" name="designation" component="div" />
            </div>
            <div className="form-group mb-3">
              <label className="form-label" htmlFor="role">Role</label>
              <Field
                className="form-control"
                name="role"
                type="text"
                placeholder="Role"
                readOnly
              />
            </div>
            <Button
              type="submit"
              className="btn btn-success w-100"
              icon={isSubmitting && <Spinner animation="border" size="sm" />}
              disabled={isSubmitting}
              text={isSubmitting ? "Processing..." : "Update Now"}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProfileForm;
