import { useEffect, useRef, useState } from "react";
import { image_38 } from "../../utils/images";
import { useSelector } from "react-redux";
import { Form, Field, Formik } from "formik";
import * as Yup from 'yup';
import Button from "../../components/common/Button";
import { Spinner } from "react-bootstrap";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { showAlertMessage } from "../../utils/sweetAlert";
import maskEmailsPhones from 'mask-email-phone';
import { Link, useNavigate } from "react-router-dom";
import AuthBack from "../../components/auth/AuthBack";
import AuthImage from "../../components/auth/AuthImage";

const ConfirmOTP = () => {
  //navigate
  const navigate = useNavigate();
  //Auth Login
  const signIn = useSignIn();

  const response = useSelector((state) => state.auth.authDetails);
  if (!response) {
    showAlertMessage({
      icon: 'error',
      title: 'Error occurred!',
      text: 'Something went wrong. Please try again',
    });
    navigate('/auth/login')
  }

  const inputRefs = useRef([]);
  const [otpCount, setOtpCount] = useState(60);

  const handleKeyUp = (e, index) => {
    const { maxLength, value } = e.target;
    if (value.length >= maxLength && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (value.length === 0 && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (otpCount > 0) {
      const timer = setInterval(() => setOtpCount(c => c - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [otpCount]);

  const initialValues = Array(6).fill('');
  const validationSchema = Yup.object().shape(
    initialValues.reduce((acc, _, idx) => {
      acc[idx] = Yup.string().required('OTP is required');
      return acc;
    }, {})
  );

  const onSubmit = async (values, actions) => {
    try {
      const enteredOTP = Object.values(values).join('');
      if (response.otp) {
        const decodedOTP = atob(response.otp);
        if (enteredOTP === decodedOTP) {
          signIn({
            auth: {
              token: response.token,
              type: 'Bearer'
            },
            expiresIn: 3600,
            authState: { mobile: response.details.mobile }
          });
          localStorage.setItem('_Auth_id', response.details._id);
          await showAlertMessage({ text: 'Login successfully' });
          navigate('/');
        } else {
          throw new Error('Invalid OTP, please enter the correct OTP');
        }
      } else {
        throw new Error('Something went wrong kindly. Please try again later.');
      }

    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <AuthBack />
      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          <div className="text-center">
            <AuthImage image={image_38} />
            <h3>Verify your mobile number</h3>
            <p className="mb-4">Enter the OTP code sent to <strong>{response && maskEmailsPhones(response?.details?.mobile)}</strong></p>
          </div>
          <div className="otp-verify-form mt-4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="input-group mb-3 otp-input-group">
                    {initialValues.map((_, index) => (
                      <Field
                        key={index}
                        autoFocus={index === 0 ? true : false}
                        name={String(index)}
                        className="form-control"
                        maxLength="1"
                        innerRef={(el) => (inputRefs.current[index] = el)}
                        onKeyUp={(e) => handleKeyUp(e, index)}
                      />
                    ))}
                  </div>
                  <Button type="submit" className="btn btn-primary" icon={isSubmitting && <Spinner animation="border" size="sm" />} disabled={isSubmitting} text={isSubmitting ? "Processing..." : "Verify & Proceed"} />
                </Form>
              )}
            </Formik>
          </div>
          <div className="login-meta-data text-center">
            <p className="mt-3 mb-0">
              Don't receive the OTP? &nbsp;
              {otpCount > 0 ? (
                <span className="otp-sec">Wait {otpCount} secs</span>
              ) : (
                <Link to="#" onClick={() => setOtpCount(60)}>Resend OTP</Link>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOTP;
