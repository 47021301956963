import { AutoComplete } from 'primereact/autocomplete';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOPDSearch } from '../../store/slices/opdSearchSlice';
import { useEffect, useState } from 'react';

const PatientConditions = ({ formik }) => {
    const { OPDOptions, OPDisLoading } = useSelector((state) => state.opdSearch);
    const dispatch = useDispatch();

    const handleSearchOPD = (query) => {
        dispatch(fetchOPDSearch(query));
    };

    const [filteredComplaints, setFilteredComplaints] = useState([]);
    const [filteredDiagnosis, setFilteredDiagnosis] = useState([]);
    const [filteredExamination, setFilteredExamination] = useState([]);
    const [filteredHistory, setFilteredHistory] = useState([]);

    // Update filtered options for each field when OPDOptions changes
    useEffect(() => {
        const complaints = OPDOptions.map((option) => option.complaints);
        const diagnosis = OPDOptions.map((option) => option.diagnosis);
        const examination = OPDOptions.map((option) => option.examination);
        const history = OPDOptions.map((option) => option.history);

        setFilteredComplaints(complaints);
        setFilteredDiagnosis(diagnosis);
        setFilteredExamination(examination);
        setFilteredHistory(history);
    }, [OPDOptions]);

    return (
        <>
            <div className="row g-lg-2 mb-0">
                <span className='text-danger'>Note: Line separated by comma (,) Like: PA: SOFT,</span>
                <div className="col-md-6">
                    <div className="form-group mb-3 card">
                        <label className="form-label">Chief Complaints</label>
                        <AutoComplete
                            value={formik.values.complaints || ''}
                            suggestions={filteredComplaints}
                            completeMethod={(e) => handleSearchOPD(e.query)}
                            field="complaints"
                            placeholder="Chief Complaints"
                            loading={OPDisLoading.toString()}
                            inputClassName="form-control"
                            onChange={(e) => formik.setFieldValue('complaints', e.value)}
                            // onSelect={handleSelect}
                            itemTemplate={(complaint) => (
                                <div>
                                    <span>{complaint}</span>
                                </div>
                            )}
                        />

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3 card">
                        <label className="form-label">Diagnosis</label>
                        <AutoComplete
                            value={formik.values.diagnosis || ""}
                            suggestions={filteredDiagnosis}
                            field='diagnosis'
                            completeMethod={(e) => handleSearchOPD(e.query, "diagnosis")}
                            placeholder="Diagnosis"
                            loading={OPDisLoading.toString()}
                            inputClassName={`form-control ${formik.errors.diagnosis && formik.touched.diagnosis ? "is-invalid" : ""
                                }`}
                            onChange={(e) => formik.setFieldValue("diagnosis", e.value)}
                            itemTemplate={(diagnosis) => <div>{diagnosis}</div>}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3 card">
                        <label className="form-label">Examination</label>
                        <AutoComplete
                            value={formik.values.examination || ""}
                            suggestions={filteredExamination}
                            field='examination'
                            completeMethod={(e) => handleSearchOPD(e.query, "examination")}
                            placeholder="Examination"
                            loading={OPDisLoading.toString()}
                            inputClassName={`form-control ${formik.errors.examination && formik.touched.examination ? "is-invalid" : ""
                                }`}
                            onChange={(e) => formik.setFieldValue("examination", e.value)}
                            itemTemplate={(examination) => <div>{examination}</div>}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3 card">
                        <label className="form-label">Past Medical History</label>
                        <AutoComplete
                            value={formik.values.history || ""}
                            suggestions={filteredHistory}
                            field='history'
                            completeMethod={(e) => handleSearchOPD(e.query, "history")}
                            placeholder="Past Medical History"
                            loading={OPDisLoading.toString()}
                            inputClassName={`form-control ${formik.errors.history && formik.touched.history ? "is-invalid" : ""
                                }`}
                            onChange={(e) => formik.setFieldValue("history", e.value)}
                            itemTemplate={(history) => <div>{history}</div>}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PatientConditions
