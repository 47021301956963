import { useSelector } from "react-redux";
import ProfileForm from "../../components/profile/ProfileForm";
import ProfileHeader from "../../components/profile/ProfileHeader";

const Profile = () => {
  const userInfo = useSelector((state) => state.user.userDetails);
  return (
    <>
      <div className="page-content-wrapper py-3">
        <div className="container">
          <ProfileHeader userInfo={userInfo}/>
          <div className="card user-data-card">
            <div className="card-body">
              <ProfileForm userInfo={userInfo}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default Profile
