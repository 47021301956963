import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
export const Doctor = ({ formik, onChange = (selectedOption) => {
    formik.setFieldValue('doctor', selectedOption ? selectedOption.value : '');
    formik.setFieldTouched('doctor', true);
}, autoFocus, name = "doctor", placeholder = "Doctor" }) => {
    const doctorInfo = useSelector((state) => state.doctor.doctorDetails);
    const [doctor, setDoctor] = useState([]);

    useEffect(() => {
        if (doctorInfo) {
            setDoctor([{ value: '', label: 'Select a doctor' }, ...doctorInfo.map((item) => ({ value: item._id, label: item.name }))]);
        }
    }, [doctorInfo]);

    return (
        <>
            <Select
                name={name}
                placeholder={placeholder}
                options={doctor}
                value={doctor.find(option => option.value === formik.values.doctor)}
                onChange={onChange}
                autoFocus={autoFocus}
            />
        </>
    )
}
