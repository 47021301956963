export const appName = "MediGeniX";

export const homeMenu = [
  {
    col: "col-3",
    menu: [
      {
        link: "/opd/add",
        title: "",
        icon: "ADD OPD",
        iconColor: "text-success",
      },
      {
        link: "/opd/list",
        title: "",
        icon: "OPD LIST",
        iconColor: "text-danger",
      },
      {
        link: "/ipd/add",
        title: "",
        icon: "ADD IPD",
        iconColor: "text-success",
      },
      {
        link: "/ipd/list",
        title: "",
        icon: "IPD LIST",
        iconColor: "text-danger",
      },
    ],
  },
  {
    col: "col-3",
    menu: [
      {
        link: "/fitness/add",
        title: "",
        icon: "ADD FC",
        iconColor: "text-success",
      },
      {
        link: "/fitness/list",
        title: "",
        icon: "FC LIST",
        iconColor: "text-danger",
      },
      {
        link: "/discharge-certificate/add",
        title: "",
        icon: "ADD DC",
        iconColor: "text-success",
      },
      {
        link: "/discharge-certificate/list/",
        title: "",
        icon: "DC LIST",
        iconColor: "text-danger",
      },
    ],
  },
  {
    col: "col-3",
    menu: [
      // {
      //   link: "",
      //   title: "ADD BILL",
      //   icon: "B",
      //   iconColor: "text-success",
      // },
      // {
      //   link: "",
      //   title: "BILL LIST",
      //   icon: "B",
      //   iconColor: "text-danger",
      // },
      // {
      //   link: "",
      //   title: "ADD SCALP",
      //   icon: "S",
      //   iconColor: "text-success",
      // },
      {
        link: "/scalp/manage",
        title: "",
        icon: "SCALP",
        iconColor: "text-danger",
      },
    ],
  },
  {
    col: "col-3",
    menu: [
      {
        link: "",
        title: "",
        icon: "A/C",
        iconColor: "text-primary",
      },
      {
        link: "",
        title: "",
        icon: "REPORT",
        iconColor: "text-success",
      },
      {
        link: "/settings",
        title: "",
        icon: "SETTINGS",
        iconColor: "text-warning",
      },
      {
        link: "/auth/logout",
        title: "",
        icon: "LOGOUT",
        iconColor: "text-danger",
      },
    ],
  },
];

export const footerMenu = [
  {
    title: "Home",
    icon: "fa-solid fa-house-circle-check",
    url: "/",
    status: "active",
  },
  { title: "OPD", icon: "fa-solid fa-user-nurse", url: "/opd/add", status: "" },
  { title: "IPD", icon: "fa-solid fa-bed-pulse", url: "/ipd/add", status: "" },
  { title: "Reports", icon: "fa-solid fa-print", url: "/reports", status: "" },
  {
    title: "Settings",
    icon: "fa-solid fa-sliders",
    url: "/settings",
    status: "",
  },
];

export const SideMenu = [
  { title: "Home", icon: "fa-solid fa-house-circle-check", url: "/" },
  { title: "OPD", icon: "fa-solid fa-user-nurse", url: "/opd/add" },
  { title: "IPD", icon: "fa-solid fa-bed-pulse", url: "/ipd/add" },
  { title: "Fitness", icon: "fa-solid fa-heart-pulse", url: "/fitness/add" },
  { title: "Discharge", icon: "fa-solid fa-list-check", url: "/discharge-certificate/add" },
  {
    title: "Billing",
    icon: "fa-solid fa-indian-rupee-sign",
    url: "/billing/add",
  },
  { title: "Scalp", icon: "fa-solid fa-syringe", url: "/scalp/manage" },
  { title: "Account", icon: "fa-solid fa-building-columns", url: "/scalp/add" },
  { title: "Reports", icon: "fa-solid fa-print", url: "/reports" },
  { title: "Settings", icon: "fa-solid fa-sliders", url: "/settings" },
  { title: "Profile", icon: "fa-regular fa-user", url: "/settings/profile" },
  {
    title: "Logout",
    icon: "fa-solid fa-power-off text-danger",
    url: "/auth/logout",
  },
];

export const baseUrl = "https://api.hayathospital.co.in/back-office/"
export const imageUrl = "https://api.hayathospital.co.in/";
