import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { footerMenu } from "../../utils/configs";
import { pageStep } from "../../utils/pageStep";

const FooterNav = () => {
  const { pathname } = useLocation();
  const pageInfo = pageStep(pathname);
  
  const [activeUrl, setActiveUrl] = useState(pageInfo.pageUrl);

  useEffect(() => {
    setActiveUrl(pageInfo.pageUrl);
  }, [pageInfo.pageUrl]);

  return (
    <div className="footer-nav-area" id="footerNav">
      <div className="container px-0">
        <div className="footer-nav position-relative">
          <ul className="h-100 d-flex align-items-center justify-content-between ps-0">
            {footerMenu.map((item) => (
              <li
                key={item.url} // It's better to use a unique identifier like `item.url` instead of `index`
                className={activeUrl === item.url ? 'active' : ''}
              >
                <Link to={item.url}>
                  <h4 className={item.icon}></h4>
                  <span>{item.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterNav;
