import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; // Import js-cookie for handling cookies
import { baseUrl } from '../../utils/configs';
import cookie from 'react-cookies'

// Base URL for API
const API_URL = `${baseUrl}bed`;

// Get the auth token from the cookie
const AuthToken = await cookie.load('_auth')

// Async thunk to fetch all Bed records
export const fetchBeds = createAsyncThunk('bed/fetchBeds', async (query) => {
  const response = await axios.get(`${API_URL}`, {
    headers: {
      Authorization: AuthToken,
    },
    params: query,
  });
  return response.data;
});

// Async thunk to add a new Bed record
export const addBed = createAsyncThunk('bed/addBed', async (newBed) => {
  const response = await axios.post(`${API_URL}`, newBed, {
    headers: {
      Authorization: AuthToken,
    },
  });
  return response.data;
});

// Async thunk to update an existing Bed record
export const updateBed = createAsyncThunk('bed/updateBed', async ({ id, updatedBed }) => {
  const response = await axios.put(`${API_URL}/${id}`, updatedBed, {
    headers: {
      Authorization: AuthToken,
    },
  });
  return response.data;
});

// Async thunk to delete an Bed record
export const deleteBed = createAsyncThunk('bed/deleteBed', async (id) => {
  const response = await axios.delete(`${API_URL}/${id}`, {
    headers: {
      Authorization: AuthToken,
    },
  });
  return response.data;
});

const bedSlice = createSlice({
  name: 'bed',
  initialState: {
    beds: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Fetch Beds
    builder
      .addCase(fetchBeds.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBeds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.beds = action.payload.data;
      })
      .addCase(fetchBeds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // Add Bed
    builder
      .addCase(addBed.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.beds.push(action.payload.data)
      });

    // Update Bed
    builder
      .addCase(updateBed.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBed.fulfilled, (state, action) => {
        const index = state.beds.findIndex((bed) => bed._id === action.payload._id);
        if (index !== -1) {
          state.beds[index] = action.payload;
        }
      })
      .addCase(updateBed.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
    // Delete Bed
    builder
      .addCase(deleteBed.fulfilled, (state, action) => {
        state.beds = state.beds.filter((bed) => bed._id !== action.payload);
      });
  },
});

export const bedReducer = bedSlice.reducer;
