import { Link, useNavigate } from "react-router-dom";
import { appName } from "../../utils/configs";
import { image_36 } from "../../utils/images";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Spinner } from "react-bootstrap";
import Button from "../../components/common/Button";
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/slices/authSlice";
import AuthImage from "../../components/auth/AuthImage";
import { showAlertMessage } from "../../utils/sweetAlert";

const Login = () => {
  // Navigate and dispatch hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPass, setShowPass] = useState(false);

  // Form initial values
  const initialValues = {
    mobile: '',
    password: ''
  };

  // Form validation schema using Yup
  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, 'Invalid Indian mobile number')
      .required('Mobile number is required'),
    password: Yup.string()
      .required('Password is required'),
  });

  const onSubmit = async (values, actions) => {
    try {
      const response = await dispatch(loginUser(values));
      if (response?.payload?.success) {
        navigate('/auth/otp-confirm');
      } else {
        throw new Error(response?.payload?.message);
      }
    } catch (error) {
      showAlertMessage({
        icon: 'error',
        title: 'Error occurred!',
        text: error.message,
      });
    } finally {
      actions.setSubmitting(false);
    }
  }

  return (
    <>
      <div className="login-wrapper d-flex align-items-center justify-content-center">
        <div className="custom-container">
          <AuthImage image={image_36} />
          <div className="register-form mt-4">
            <h6 className="mb-3 text-center">Log in to continue to the {appName}</h6>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <Field
                      className="form-control"
                      type="text"
                      name="mobile"
                      placeholder="Mobile Number"
                      maxLength={10}
                      size={10}
                      autoFocus
                    />
                    <ErrorMessage className="text-danger mb-1" name="mobile" component="div" />
                  </div>
                  <div className="form-group position-relative">
                    <Field
                      className="form-control"
                      type={showPass ? 'text' : 'password'}
                      placeholder="Enter Password"
                      name="password"
                    />
                    <ErrorMessage className="text-danger mb-1" name="password" component="div" />
                    <div className="position-absolute" id="password-visibility" onClick={() => setShowPass(!showPass)} style={{ right: 10, top: 10, cursor: 'pointer' }}>
                      {showPass ? <FaEyeSlash /> : <FaEye />}
                    </div>
                  </div>
                  <Button
                    type="submit"
                    className="btn btn-primary w-100"
                    icon={isSubmitting && <Spinner animation="border" size="sm" />}
                    disabled={isSubmitting}
                    text={isSubmitting ? "Processing..." : "Login"}
                  />
                </Form>
              )}
            </Formik>
          </div>
          <div className="login-meta-data text-center">
            <Link className="stretched-link forgot-password d-block mt-3 mb-1" to="/auth/forgot-password">Forgot Password?</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
