import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import cookie from 'react-cookies'
import { baseUrl } from '../../utils/configs';

const AuthToken = await cookie.load('_auth')

// Async thunk to handle get patient information
export const fetchPatients = createAsyncThunk(
  'auth/fetchPatients',
  async (query, thunkAPI) => {
    try {
      const response = await axios.get(`${baseUrl}patient?search=${query}`, {
        headers: {
          Authorization: AuthToken,
        }
      });
      return response.data.success ? response.data : { success: true, data: [{ name: query }] };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);


const patientSlice = createSlice({
  name: 'patient',
  initialState: {
    isLoading: false,
    isError: false,
    options: []
  },
  reducers: {
    options(state, action) {
      state.options = action.payload;
      state._id = action.payload._id;
    }
  },
  extraReducers: (builder) => {
    builder
      // Get patient by id
      .addCase(fetchPatients.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchPatients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.options = action.payload.data;
        state.isError = false;
      })
      .addCase(fetchPatients.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
  },
});

export const { options } = patientSlice.actions;

export const patientReducer = patientSlice.reducer;