
const Internet = () => {
  return (
    <>
      <div className="internet-connection-status" id="internetStatus"></div>
    </>
  )
};

export default Internet
